import http from './http';
import { BASEURL } from '../store/constants';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

export async function loadColunm() {
	const response = http.post(
		BASEURL,
		{
			table: 'nimda-emp',
			task: 'gettable',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadEmploye() {
	const response = http.post(
		BASEURL,
		{
			task: 'loadEmploye',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadStatisticEmploye(id, dateS, dateF) {
	const response = http.post(
		BASEURL,
		{
			task: 'loadStatisticEmploye',
			id: id,
			dateS: dateS,
			dateF: dateF,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function saveEmploye(arr) {
	const response = http.post(
		BASEURL,
		{
			task: 'saveEmploye',
			token: store.getters['base/getToken'],
			arr: arr
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadEmployeSalaryByDay() {
	const response = http.post(
		BASEURL,
		{
			task: 'loadEmployeSalaryDays',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function saveEmployeSalaryDays({
	arr, sum, empId, dates, cashAdvance, newCashAdvance, deducted, calc
}) {
	const response = http.post(
		BASEURL,
		{
			arr: arr,
			sum: sum,
			empId: empId,
			dates: dates,
			cashAdvance: cashAdvance,
			newCashAdvance: newCashAdvance,
			deducted: deducted,
			calc: calc,
			task: 'saveEmployeSalaryDays',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function addCashAdvanceEmp({ sum, idEmp }) {
	const response = http.post(
		BASEURL,
		{
			task: 'addCashAdvanceEmp',
			token: store.getters['base/getToken'],
			ca: sum,
			idEmp: idEmp
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadColunmEmpSalary() {
	const response = http.post(
		BASEURL,
		{
			table: 'workersalary',
			task: 'gettable',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function DismissEmp(id) {
	const response = http.post(
		BASEURL,
		{
			task: 'DismissEmp',
			id: id,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

import http from './http';
import { BASEURL } from '../store/constants';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

export async function LogIn({ email, password }) {
	const response = http.post(
		BASEURL,
		{
			email: email,
			password: password,
			task: 'login',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function exit(id) {
	const response = http.post(
		BASEURL,
		{
			task: 'exit',
			id: id,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function chkToken() {
	const response = http.post(
		BASEURL,
		{
			task: 'chkToken',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function workTime({ accCode, timeOfDay }) {
	const response = http.post(
		BASEURL,
		{
			task: 'workTime',
			timeOfDay: timeOfDay,
			workTimeLoc: accCode,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadMenu() {
	const response = http.post(
		BASEURL,
		{
			task: 'menu',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

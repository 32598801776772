<template>
	<div
		class="menu-container"
		:style="{ height: `${getMainScreenSizeY}px` }">
		<ul>
			<li
				v-for="item in menuOptions(getMainMenu)"
				:key="item.Call"
				class="menu-item">
				<ButtonPV
					class="w125 ms-1"
					severity="secondary"
					:disabled="item.disabled"
					:label="item.Name"
					@click="selectMenu(item.Call)" />
			</li>
		</ul>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ButtonPV from 'primevue/button';

export default {
	components: {
		ButtonPV
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeY']),
		...mapGetters('nimdaMain', ['getMainMenu', 'getMenuData'])
	},
	methods: {
		...mapActions('nimdaMain', ['selectMenu']),
		menuOptions(menuName) {
			if (menuName === 'main') return this.getMenuData;
			const foundItem = this.getMenuData.find(el => el.Call === menuName);
			if (!foundItem) return [];
			return foundItem.options;
		}
	}
};
</script>

<style scoped>
.menu-container {
	width: 145px;
	min-width: 144px;
	float: left;
}

.menu-item {
	margin-bottom: 2px;
	margin-left: 4px;
}

ol, ul {
	list-style-type: none;
	margin-left: 0;
	padding: 0;
}
</style>

// eslint-disable-next-line import/no-unresolved, import/extensions, import/no-cycle
import * as userApi from '@/api/user';

const sha256 = require('js-sha256');

export default {
	namespaced: true,
	state: {
		userColumn: [],
		loadColumn: false,
		userData: [],
		loadData: false
	},
	getters: {
		getUserColumn: state => state.userColumn, // колонки для таблицы юзер
		getLoadColumn: state => state.loadColumn, // загружены ли колонки таблицы юзер
		getUserData: state => state.userData, // данные для таблицы юзер
		getLoadData: state => state.loadData, // загружены ли данные
		hasEditedUsers: state => state.userData.some(user => user.edited)
	},
	mutations: {
		setUserColumn(state, val) {
			state.userColumn = val;
			state.loadColums = true;
		},
		setUserData(state, val) {
			state.userData = val;
			state.loadData = true;
		}
	},
	actions: {
		// загружаем заголовки для колонок user таблицы
		async loadUserGridHead({ rootGetters, commit, getters }) {
			if (rootGetters['base/getGlobError'] === 0 && !getters.getLoadColumn) {
				const response = await userApi.loadGridColumn('user', 'gettable');
				if (response.data.error === 0) {
					commit('setUserColumn', response.data.data);
				}
			}
		},
		// сохраняет измененные строки user таблицы
		async saveUserGridData({ rootGetters, commit, getters }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const arr = getters.getUserData.filter(el => (el.edited));
			if (arr.length <= 0) return;
			arr.forEach((el) => {
				if (el.editedpwd) el.password = sha256(el.password);
			});
			const response = await userApi.saveGridData('user', 'gettable');
			commit('updateUserGridData', response.data.data);
		},
		// загружаем данные для user таблицы
		async loadUserGridData({ rootGetters, commit, getters }) {
			if (rootGetters['base/getGlobError'] === 0 && !getters.getLoadData) {
				const response = await userApi.loadGridData();
				if (response.data.error === 0) {
					commit('setUserData', response.data.data);
				}
			}
		}
	}
};

<!-- eslint-disable import/extensions -->
<template>
	<el-dialog
		v-model="getOpenTimeWorcDial"
		title="Registration"
		width="400px">
		<el-card>
			<el-text type="primary">
				Get a time of work
			</el-text>
			<el-input
				v-model="workTimeVal"
				placeholder="Place the cursor on this input."
				class="mt-2"
			/>
			<el-radio-group v-model="radio">
				<el-radio
					value="sw"
					class="w125">Start of work
				</el-radio>
				<el-radio
					class="w125"
					value="sl">Start of Lanch
				</el-radio>
				<el-radio
					value="fw"
					class="w125">
					Finish of work
				</el-radio>
				<el-radio
					value="fl"
					class="w125">
					Finish of Lanch
				</el-radio>
			</el-radio-group>
			<span style="height: 15px;" />
		</el-card>
		<template #header>
			<el-text>ttttttt</el-text>
		</template>
		<template #footer>
			<span class="dialog-footer">
				<el-button
					type="primary"
					@click="submitData"
				>
					Submit
				</el-button>
				<el-button
					type="primary"
					@click="setOpenTimeWorcDial(false)"
				>
					Cancel
				</el-button>
			</span>
		</template>
	</el-dialog>
</template>
<script>
import {
	ElMessage,
	ElButton,
	ElDialog,
	ElRadio,
	ElRadioGroup,
	ElInput,
	ElText,
	ElCard
} from 'element-plus';
import { mapActions, mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils.js';

export default {
	components: {
		ElDialog,
		ElRadioGroup,
		ElText,
		ElRadio,
		ElInput,
		ElButton,
		ElCard
	},
	computed: {
		...mapGetters('nimdaEmp', ['getOpenTimeWorcDial'])
	},
	methods: {
		...mapMutations('nimdaEmp', ['setOpenTimeWorcDial']),
		...mapActions('base', ['workTime']),
		submitData() {
			if (utils.isValidPhoneNumber(this.workTimeVal)) {
				this.workTime({ accCode: this.workTimeVal, radio: this.radio });
			} else {
				ElMessage({
					type: 'error',
					message: 'The entered data is incorrect',
					showClose: true,
					duration: 10000
				});
			}
		}
	},
	data() {
		return {
			workTimeVal: '',
			singInWorkTime: true,
			radio: 'sw'
		};
	},
	props: {
		// workTimeVal: { type: String, default: '' },
		// radio: { type: String, required: true, default: 'sw' }
	}
};
</script>

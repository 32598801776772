<template>
	<DialogPV
		v-model:visible="showDialog"
		width="952px"
		header="Employer Information">
		<emp-select
			:margintop="2"
			widthclass="w220"
		/>
		<p />
		<div class="scrollable">
			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0"
					type="success"
				>
					Name:
					<span
						class="mt-1"
						style="color: green">
						{{ empName }}
					</span>
				</div>
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0">
					Middle Name:
					<span
						class="mt-1"
						style="color: green">
						{{ empMiddleName }}
					</span>
				</div>
			</div>
			<div class="ms-0 border border-last">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Last name:
					<span
						style="color: green">
						{{ empLastName }}
					</span>
				</div>
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Salary Rate:
					<span
						class="mt-1"
						style="color: green">
						{{ empSalaryRate }}
					</span>
				</div>
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Salary period:
					<span
						class="mt-1"
						style="color: green">
						{{ empSalaryPeriod }}
					</span>
				</div>
			</div>
			<div class="ms-0 border border-last">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Extra Time Salary:
					<span
						class="mt-1"
						style="color: green">
						{{ empExtraSalary }}
					</span>
				</div>
			</div>

			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Cash Advance:
					<span
						class="mt-1"
						style="color: green">
						{{ empCashAdvance }}
					</span>
				</div>
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-4 ms-3 mb-0"
				>
					Date of Start Job:
					<span
						class="mt-1"
						style="color: green">
						{{ empTimeOfJobStart }}
					</span>
				</div>
			</div>
			<div
				class="ms-0 border border-last"
				style="border-bottom: 0;">
				<div id="block1">
					<span
						class="w100pr ms-3 mb-0"
					>
						Personal key:
					</span>
				</div>
				<div id="block2">
					<i
						v-if="empAccCode"
						class="pi pi-exclamation-circle ms-2 mt-3"
						style="font-size: 1.2rem; color: red" />
					<i
						v-else
						class="pi pi-check-circle ms-2 mt-3"
						style="font-size: 1.2rem; color: green" />
				</div>
			</div>

		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					label="Cancel"
					severity="danger"
					@click="setEmpInfWinShow(false)" />
			</span>
		</template>
	</DialogPV>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
// eslint-disable-next-line import/no-unresolved
import EmpSelect from '@/components/other/EmpSelect.vue';

export default {
	components: {
		EmpSelect,
		DialogPV,
		ButtonPV
	},
	data() {
		return {
			emp: {
				id: 0,
				name: '',
				midlName: '',
				lastName: '',
				cashAdvance: 0,
				timeOfLastUpdateD: 0,
				timeOfJobStart: 0,
				accCode: 0,
				Dismissed: 0,
				DismissedDate: 0,
				lunchBreak: 0,
				salary: 0,
				extraTimeSalary: 0,
				saved: false,
				perDayOrHours: 'Day'
			}
		};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getEmployeFromSelect',
				'getEmpInfWinShow',
				'getEmployeesById'
			]
		),
		showDialog: {
			get() {
				return this.getEmpInfWinShow;
			},
			set(v) {
				this.setEmpInfWinShow(v);
			}
		},
		empName() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.name;
			return this.getEmployeesById(id).name;
		},
		empMiddleName() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.midlName;
			return this.getEmployeesById(id).midlName;
		},
		empLastName() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.lastName;
			return this.getEmployeesById(id).lastName;
		},
		empSalaryRate() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.salary;
			return this.getEmployeesById(id).salary;
		},
		empExtraSalary() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.extraTimeSalary;
			return this.getEmployeesById(id).extraTimeSalary;
		},
		empSalaryPeriod() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.perDayOrHours;
			return this.getEmployeesById(id).perDayOrHours;
		},
		empCashAdvance() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.perDayOrHours;
			return this.getEmployeesById(id).cashAdvance;
		},
		empTimeOfJobStart() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.perDayOrHours;
			return this.getEmployeesById(id).timeOfJobStart;
		},
		empAccCode() {
			const id = this.getEmployeFromSelect;
			if (id === 0) return this.emp.perDayOrHours;
			return this.getEmployeesById(id).accCode > 0;
		}
	},
	methods: {
		...mapMutations(
			'nimdaEmp',
			[
				'setEmpInfWinShow',
				'setEmployeDeducted'
			]
		)
	}
};
</script>>
<style scoped>
#block1 {
	float: left;
	display: block;
	padding-top: 24px;
	width: 110px;
	margin: 2px;
}

#block2 {
	float: left;
	display: block;
	padding-top: 4px;
	margin: 2px;
}
.border {
	border-top: 0px;
	border-right: 1px;
	border-left: 0px;
	border-bottom: 1px;
	border-style: solid;
	width: 306px !important;
	height: 90px;
	padding: 0px 0px 0px 0px;
	margin: 0;
}
.border-last {
	border-right: 0px;
}
.scrollable {
	height: 270px;
	width: 922px;
	border-style: solid;
	border-width: 2px;
	display: flex;
	flex-wrap: wrap;
}
.info-div {
	height: 350px;
	width: 200px;
	border-style: solid;
	border-width: 1px;
	padding: 5px;
}
</style>

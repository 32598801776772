// eslint-disable-next-line import/extensions, import/no-unresolved
import http from '@/api/http';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { BASEURL } from '@/store/constants';
// eslint-disable-next-line import/no-cycle, import/extensions, import/no-unresolved
import store from '@/store/index';

export async function loadGridColumn(table, task) {
	const response = http.post(
		BASEURL,
		{
			table: table,
			task: task,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function loadGridData() {
	const response = http.post(
		BASEURL,
		{
			task: 'userdata',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function saveGridData(arr) {
	const response = http.post(
		BASEURL,
		{
			task: 'saveusertable',
			arr: arr,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

<template>
	<DialogPV
		v-model:visible="ShowACard"
		width="500px"
		header="Employe a card ID set">
		<emp-select
			widthclass="w430"
		/>
		<div v-if="getEmployeBySelAccCode === 0">
			<InputText
				v-model="inputLoc"
				placeholder="Put cursor here and attache card to card holder."
				class="mt-2 w430" />
			<validate-sing
				:validateVal="!validateData"
			/>
		</div>
		<div
			v-if="getEmployeBySelAccCode > 0"
			class="mt-3">
			<span
				class="mt-1 me-4"
				style="color: green">
				This user has a card ID set.
				<i
					class="pi pi-check"
					style="font-size: 1.5rem; margin-left: 20px;" />
			</span>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					severity="danger"
					label="Cancel"
					@click="setShowAddCard(false)" />
				<ButtonPV
					v-if="getEmployeBySelAccCode > 0"
					type="primary"
					class="ms-2"
					@click="saveEmployeCode(0);">
					Dismiss card ID set
				</ButtonPV>
				<ButtonPV
					v-if="getEmployeBySelAccCode > 0"
					type="success"
					class="ms-2"
					@click="checkCardIdSet(this.getEmployeFromSelect);">
					Check card ID set
				</ButtonPV>
				<ButtonPV
					v-if="validateData && getEmployeBySelAccCode === 0"
					type="success"
					class="ms-2"
					:disabled="!validateData"
					@click="saveEmployeCode(inputLoc);">
					Save
				</ButtonPV>
			</span>
		</template>
	</DialogPV>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
import InputText from 'primevue/inputtext';
// eslint-disable-next-line import/no-unresolved
import EmpSelect from '@/components/other/EmpSelect.vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import utils from '@/utils/utils';
// eslint-disable-next-line import/no-unresolved
import ValidateSing from '@/components/other/ValidateSing.vue';

export default {
	components: {
		EmpSelect,
		ButtonPV,
		InputText,
		DialogPV,
		ValidateSing
	},
	data() {
		return {
			inputLoc: ''
		};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getEmployeFromSelect',
				'getEmployeesSalary',
				'getShowAddCard',
				'getEmployeBySelAccCode'
			]
		),
		input: {
			get() {
				return this.getEmployeBySelAccCode === 0 ? '' : this.getEmployeBySelAccCode;
			},
			set(v) {
				this.setEmployeBySel(v);
			}
		},
		ShowACard: {
			get() {
				return this.getShowAddCard;
			},
			set(v) {
				this.setShowAddCard(v);
			}
		},
		validateData() {
			if (utils.checkInt10(this.inputLoc)) return true;
			return false;
		}
	},
	methods: {
		...mapActions(
			'nimdaEmp',
			[
				'DismissEmp',
				'setEmployeFromSelect',
				'setEmployeBySel',
				'checkCardIdSet',
				'saveEmployeCode'
			]
		),
		...mapMutations(
			'nimdaEmp',
			[
				'setDismissEmp',
				'setShowAddCard'
			]
		)
	}
};
</script>>
<style scoped>
.scrollable {
	overflow: hidden;
	overflow-y: scroll;
	height: 350px;
	width: 720px;
	border-style: solid;
	border-width: 1px;
}
.info-div {
	height: 350px;
	width: 200px;
	border-style: solid;
	border-width: 1px;
	padding: 5px;
}
</style>

<template>
	<DataTable
		:value="getEmployees"
		tableStyle="min-width: 80rem">
		<Column
			v-for="col of getEmployeTableCol"
			:key="col.field"
			:field="col.field"
			:header="col.header" />
		<Column style="flex: 0 0 4rem">
			<template #body="{ data, index }">
				<ButtonPV
					type="button"
					:icon="'pi pi-pencil'"
					text
					size="small"
					@click="toggleLock(data, index, 'edit')" />
				<ButtonPV
					type="button"
					:icon="'pi pi-cog'"
					text
					size="small"
					@click="toggleLock(data, index, 'salary')" />
			</template>
		</Column>
	</DataTable>
	<!-- статистика для работника -->
	<nimda-statistics-drawer />
	<!-- диалоговое окно для рассчта зп -->
	<nimda-calc-emp-dialog />
	<!-- диалоговое окно для внесения аванса -->
	<nimda-cash-advance-dial />
	<!-- диалоговое окно для увольнения работника -->
	<nimda-dismiss-emp-dial />
	<!-- диалоговое окно для внессения нового и редактирования данных работника -->
	<nimda-emp-edit-dial />
	<!-- диалоговое окно добавления работника -->
	<n-e-add-card />
	<!-- Информационное окно работника -->
	<nimda-information />
	<!-- Окно для WorkTime -->
	<nimda-work-time />
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
// import { ElTableV2 } from 'element-plus';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import ButtonPV from 'primevue/button';
// eslint-disable-next-line import/no-unresolved
import NimdaCalcEmpDialog from '@/components/Nimda/NimdaEmp/NECalcSalaryD.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaWorkTime from '@/components/other/workTime.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaCashAdvanceDial from '@/components/Nimda/NimdaEmp/NECashAdvanceD.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaStatisticsDrawer from '@/components/Nimda/NimdaEmp/NEStatisticsDrawerD.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaDismissEmpDial from '@/components/Nimda/NimdaEmp/NEDismissD.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaEmpEditDial from '@/components/Nimda/NimdaEmp/NEEditD.vue';
// eslint-disable-next-line import/no-unresolved
import NEAddCard from '@/components/Nimda/NimdaEmp/NEAddCardD.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaInformation from '@/components/Nimda/NimdaEmp/NEInformationD.vue';

export default {
	components: {
		NimdaCalcEmpDialog,
		NimdaCashAdvanceDial,
		NimdaStatisticsDrawer,
		NimdaWorkTime,
		DataTable,
		Column,
		ButtonPV,
		NimdaDismissEmpDial,
		NimdaEmpEditDial,
		NEAddCard,
		NimdaInformation
	},
	props: {
		editable: { type: Boolean, default: true }
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeY', 'getMainScreenSizeX']),
		...mapGetters('nimdaEmp', ['getEmployees', 'getEmployeTableCol'])
	},
	methods: {
		...mapMutations('nimdaEmp', ['setShowEmpEditDial']),
		...mapActions('nimdaEmp', ['showEmpCalcSalary']),
		toggleLock(data, index, mode) {
			console.log(data, index, mode);
			if (mode === 'edit') this.showEmpCalcSalary({ dial: true, id: data.id });
			if (mode === 'salary') this.setShowEmpEditDial({ edit: true, id: data.id });
		}
	}
};
</script>

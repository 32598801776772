<template>
	<DialogPV
		v-model:visible="isVisible"
		modal
		header="Balance on Wallets"
	>
		<div
			v-for="item in getBalancesWalletsData"
			:key="item.sortName"
		>
			<div
				class="w200 mt-4 ms-3 mb-0"
				type="success"
			>
				<p
					class="mt-1"
					type="success"
					size="large">
					{{ item.sortName }} <span style="color: red;">{{ item.waletAmount }}</span>
				</p>
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV @click="this.setBalancesWallets(false)">
					Cancel
				</ButtonPV>
			</span>
		</template>
	</DialogPV>
</template>
<script>

import { mapGetters, mapMutations } from 'vuex';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';

export default {
	components: {
		DialogPV,
		ButtonPV
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters(
			'accaunt',
			[
				'getBalancesWallets',
				'getBalancesWalletsData'
			]
		),
		isVisible: {
			get() {
				return this.getBalancesWallets;
			},
			set(value) {
				this.setBalancesWallets(value);
			}
		}
	},
	methods: {
		...mapMutations(
			'accaunt',
			[
				'setBalancesWallets'
			]
		)
	}
};
</script>
<style scoped>
.w100 {
	width: 100px;
}
</style>

<template>
	<div v-if="show === true">
		<DataTable
			:value="data"
			editMode="cell"
			:pt="{
				table: { style: 'min-width: 50rem;' },
				column: {
					bodycell: ({ state }) => ({
						class: [{ 'pt-0 pb-0': state.d_editing }],
					}),
				},
			}"
			@cell-edit-complete="onCellClick">
			<Column
				v-for="col of getUserColumn"
				:key="col.id"
				:field="col.field"
				:header="col.header">
				<template #body="{ data, field }">
					{{ data[field] }}
				</template>
				<template #editor="{ data, field }">{{ data }}
					<InputText
						v-if="field === 'name' || field === 'secName' || field === 'email' || field === 'password'"
						v-model="data[field]"
						autofocus
						fluid />
					<InputNumber
						v-if="field === 'userType'"
						v-model="data[field]"
						autofocus
						fluid />
					<SelectPV
						v-if="field === 'lockUser'"
						v-model="selectedLockUser"
						:options="lockUser"
						optionLabel="name"
						autofocus
						placeholder="Lock User"
						class="w-full md:w-56" />
					<DatePicker
						v-if="field === 'birthDate'"
						v-model="data[field]"
						fluid
						dateFormat="dd-mm-yy"
						showButtonBar />
				</template>
			</Column>
		</DataTable>
	</div>
</template>
<script>

import { mapGetters, mapMutations } from 'vuex';
import DataTable from 'primevue/datatable';
import InputNumber from 'primevue/inputnumber';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import DatePicker from 'primevue/datepicker';
import SelectPV from 'primevue/select';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';

export default {
	components: {
		DataTable,
		InputNumber,
		DatePicker,
		Column,
		SelectPV,
		InputText
	},
	data() {
		return {
			selectedLockUser: null,
			lockUser: [
				{ name: 'Yes', code: 'Yes' },
				{ name: 'No', code: 'No' }
			]
		};
	},
	props: {
		data: { type: Array, required: true },
		show: { type: Boolean, required: true, default: false }
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeY', 'getMainScreenSizeX']),
		...mapGetters('nimdaUser', ['getUserColumn'])
	},
	methods: {
		...mapMutations('nimdaMain', ['setMenuDataByIndex']),
		onCellClick(event) {
			const {
				data, newValue, field
			} = event;
			data.edited = true;
			this.setMenuDataByIndex({
				menuIndex: 1, index: 1, nameOfVal: 'disabled', val: false
			});
			console.log(data, field, newValue);
			if (String(newValue).trim().length > 0) {
				switch (field) {
				case 'secName':
				case 'name':
				case 'email':
				case 'userType':
					data[field] = newValue;
					break;
				case 'birthDate':
					data[field] = utils.getDate(newValue);
					break;
				case 'lockUser':
					data[field] = this.selectedLockUser.name;
					break;
				default:
				}
			} else event.preventDefault();
		}
	}
};
</script>

const utils = {
	includesIgnoreCase(array, value) {
		// Приводим искомое значение к нижнему регистру
		const lowerCaseValue = value.toLowerCase();
		// Проверяем, есть ли в массиве элемент, который соответствует искомому значению
		return array.toLowerCase().includes(lowerCaseValue);
	},
	isValidPhoneNumber(input) {
		// Проверяем, что строка состоит из 10 цифр
		const regex = /^\d{10}$/;
		return regex.test(input);
	},
	splitStr(str) {
		const arr = String(str).split('|');
		return arr.join('<br />');
	},
	getMonthName(n) {
		const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		return month[n];
	},
	chkDate(date, period) {
		const d = Math.round(Date.now() / 1000);
		const add = 3600 * 24 * period;
		const roundedDate = Math.round(date / 1000);
		return d - add < roundedDate && roundedDate < d + add;
	},
	getDate(date) {
		const d = new Date(date);
		const month = (d.getMonth() + 1).toString().padStart(2, '0');
		const day = d.getDate().toString().padStart(2, '0');
		return `${day}-${month}-${d.getFullYear()}`;
	},
	getDateNow() {
		const d = new Date();
		const month = (d.getMonth() + 1).toString().padStart(2, '0');
		const day = d.getDate().toString().padStart(2, '0');
		return `${day}-${month}-${d.getFullYear()}`;
	},
	strtotime(str) {
		// Converts a date string to a Unix timestamp
		// (number of seconds since January 1, 1970). Returns 0 if the input is not a valid date.
		if (!this.checkDate(str)) {
			const [date, time] = str.split(' ');
			const [year, month, day] = date.split('-');
			if (!this.checkDate(`${day.toString().padStart(2, '0')}-${month}-${year} ${time}`)) return 0;
		}
		const d = new Date(str);
		return Number.isNaN(d.getTime()) ? 0 : Math.round(d.getTime() / 1000);
	},

	date(str, time, sepin = '-', sepout = '-') {
		// Formats a Unix timestamp into a date string based on the provided format string.
		// The format string can contain placeholders like 'Y' for year, 'd' for day, and 'm' for month.
		// The default separator for input is '-' and for output is '-'.
		// Returns the formatted date string.
		const a = new Date(time * 1000);
		const year = a.getFullYear();
		const month = (a.getMonth() + 1).toString().padStart(2, '0');
		const date = a.getDate().toString().padStart(2, '0');
		let out = '';
		str.split(sepin).forEach((el) => {
			switch (el) {
			case 'Y':
				out += year + sepout;
				break;
			case 'd':
				out += date + sepout;
				break;
			case 'm':
				out += month + sepout;
				break;
			case 'M':
				out += this.getMonthName(a.getMonth()) + sepout;
				break;
			default:
			}
		});
		return out.slice(0, -1);
	},

	time: () => Math.round(Date.now() / 1000),
	// Returns the current Unix timestamp.

	getNewId: id => `new${id}${Date.now()}`,
	// Generates a new ID by concatenating the provided id with the current Unix timestamp.

	checkDate: date => /^[\d]{4,4}-[\d]{2,2}-[\d]{2,2}/.test(String(date).trim()),
	// Checks if the input string is a valid date in the format 'YYYY-MM-DD'.
	// Returns true if valid, false otherwise.

	checkPassword: val => /^[A-Za-z\d\s!.,\\«»;"—@?/-_#$%^&*ёЁ]{6,64}$/.test(val.trim()),
	// Checks if the input string is a valid password.
	// It should contain 6 to 64 characters and can include letters,
	// digits, spaces, and certain special characters.

	checkName: val => /^[A-Za-z\s-\d]{2,}$/.test(String(val).trim()),
	// Checks if the input string is a valid name.
	// It should contain at least 2 characters and can include letters, digits, spaces, and hyphens.

	checkNameEmpty: val => /^[A-Za-z\s-\d]{0,}$/.test(val.trim()),
	// Checks if the input string is a valid name.
	// It should contain at least 0 characters and can include letters, digits, spaces, and hyphens.

	checkEmail: val => /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(val.trim()),
	// Checks if the input string is a valid email address.

	checkFormula: val => /^[\d,.*\s-+/]+$/.test(val.trim()),
	// Checks if the input string is a valid mathematical formula.
	// It should only contain digits, commas, periods,
	// spaces, and basic arithmetic operators (+, -, *, /).
	// Returns true if valid, false otherwise.

	getDateToDay: function () {
	// Returns the current date in the format 'DD-MM-YYYY'.
		const date = new Date();
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	},

	checkFloat: val => /^[+-]?\d+(\.\d*)?$/.test(val),
	// Checks if the input string is a valid floating-point number.

	checkFloatAndZero: val => /^[+-]?\d+(\.\d+)?$/.test(val) && Number(val) > 0,
	// Checks if the input string is a valid floating-point number and greater than zero.

	checkInt: a => /^[0-9]+$/.test(a) && Number(a) > 0,
	// Checks if the input string is a valid positive integer.

	checkInt10: a => /^[0-9]{10}$/.test(a),

	checkEmpty: val => String(val).trim() !== '',
	// Checks if the input string is not empty (after trimming leading and trailing spaces).

	checkMemoNotEmpty: val => /^[А-Яа-яA-Za-z\d\s!.,\\«»;"—@?-_ёЁ№]+$/.test(val.trim()),
	// Checks if the input string is not empty and only contains letters, digits,
	// spaces, and certain special characters.

	Date: function (str) {
		const { Y, m, d } = str.split('-');
		return `${d}-${m}-${Y}`;
	},
	// Converts a date string in the format 'YYYY-MM-DD' to 'DD-MM-YYYY'.

	DoNumber: function (val, a = false) {
		const numericVal = Number(String(val).replace(/\s/g, '').replace(/,/g, '.'));
		if (a) return this.number_format(numericVal, 2, '.', ' ');
		return numericVal;
	},
	// Converts a numeric string with optional thousands separator and decimal point to a number.
	// If a is true, it returns the formatted number with two decimal places and thousands separator.

	CalcAll: function (fn) {
	// Evaluates a mathematical expression represented as a string.
	// Returns the result of the expression.
		let exp = String(fn).trim();
		if (Number(exp) === 0) return 0;

		exp = String(exp).replace(/\s/g, '').replace(/,/g, '.');
		const n = exp.search(/([:?*])|([:?-])|([:?+])|([:?/])/);
		if (n > 0) {
			return this.calculate(exp);
		}
		if (!this.checkFloat(exp)) return 0;
		return exp;
	},
	calculate(str) {
	//  Evaluates a mathematical expression represented as a string.
	// Returns the result of the expression.
		const sumOrDiff = function (sub, a, sign, b) {
			return sign === '-' ? a - b : +a + +b;
		};
		const multOrDiv = function (sub, a, sign, b) {
			return sign === '*' ? a * b : a / b;
		};
		const power = function (sub, a, b) {
			return a ** b;
		};
		const matchPower = /(-?[\d.]+)\s*^\s*(-?[\d.]+)/g;
		const matchMult = /(-?[\d.]+)\s*([*])\s*(-?[\d.]+)/g;
		const matchDiv = /(-?[\d.]+)\s*([/])\s*(-?[\d.]+)/g;
		const matchSumDiff = /(-?[\d.]+)\s*([+-])\s*(-?[\d.]+)/g;
		const getValue = function (sub, exp) {
			let expCalc = exp;
			while (expCalc.indexOf('^') !== -1) {
				expCalc = expCalc.replace(matchPower, power);
			}
			while (matchDiv.test(expCalc)) {
				expCalc = expCalc.replace(matchDiv, multOrDiv);
			}
			while (matchMult.test(expCalc)) {
				expCalc = expCalc.replace(matchMult, multOrDiv);
			}
			while (matchSumDiff.test(expCalc)) {
				expCalc = expCalc.replace(matchSumDiff, sumOrDiff);
			}
			return expCalc;
		};
		let strCalc = str;
		while (strCalc.indexOf('(') !== -1) { // убираем скобки
			strCalc = strCalc.replace(/\(([^()]*)\)/g, getValue);
		}
		return getValue('', strCalc);
	},
	number_format: function (numb, decimals, decPoint, thousandsSep) {
		const toFixedFix = function (n, prec) {
			const k = 10 ** prec;
			return `${Math.round(n * k) / k}`;
		};
		const num = (`${numb}`).replace(/[^0-9+\-Ee.]/g, '');
		const n = !Number.isFinite(+num) ? 0 : +num;
		const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
		const sep = (thousandsSep === undefined) ? ',' : thousandsSep;
		const dec = (decPoint === undefined) ? '.' : decPoint;
		let s = '';
		s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	}
};
export default utils;

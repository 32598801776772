<template>
	<DialogPV
		v-model:visible="dialogVisible"
		pt:root:class="!border-0 !bg-transparent"
		justify-center
		pt:mask:class="backdrop-blur-sm">
		<div
			v-if="singInWorkTime"
			style="width: 25rem;flex-direction: column;">
			<label
				for="username"
				style="padding-right: 3px;margin-left: 30px;"
				class="me-4">E-mail:</label>
			<InputText
				id="username"
				v-model="email"
				placeholder="Please input e-mail"
				class="ml-3"
			/>
			<br />
			<small
				id="username-help"
				style="padding-left: 215px;"
				class="ml-4">Enter your username.</small>
			<br />
			<label
				for="username"
				style="padding-right: 4px;margin-left: 30px;"
				class="me-1">
				Password:
			</label>
			<InputText
				v-model="password"
				type="password"
				placeholder="Please input password"
			/>
		</div>
		<CardPV v-else>
			<label
				for="workTimeVal">
				Get a time of work
			</label>
			<InputText
				id="workTimeVal"
				v-model="workTimeVal"
				placeholder="Place the cursor on this input."
				class="mt-2 ms-2"
			/>
			<table style="border: aqua;">
				<tr>
					<td style="mt-2">
						<div class="mt-3 ms-3">
							<RadioButton
								v-model="radio"
								inputId="t2"
								name="time"
								value="sw" />
							<label
								for="t2"
								class="ms-2">Start of Work</label>
						</div>
					</td>
					<td>
						<div class="mt-3 ms-3">
							<RadioButton
								v-model="radio"
								inputId="t3"
								name="time"
								value="sl" />
							<label
								for="t3"
								class="ms-2">Start of Lanch</label>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<div class="mt-3 ms-3">
							<RadioButton
								v-model="radio"
								inputId="t4"
								name="time"
								value="fw" />
							<label
								for="t4"
								class="ms-2">Finish of Work</label>
						</div>
					</td>
					<td>
						<div class="mt-3 ms-3">
							<RadioButton
								v-model="radio"
								inputId="t5"
								name="time"
								value="fl" />
							<label
								for="t5"
								class="ms-2">Finish of Lanch</label>
						</div>
					</td>
				</tr>
			</table>
		</CardPV>
		<template #header>

			<ToggleButton
				v-model="singInWorkTime"
				onLabel="SignIn"
				offLabel="Work time"
				onIcon="pi pi-hourglass"
				offIcon="pi pi-reddit"
				class="w-36"
				aria-label="Do you confirm" />
		</template>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					:label="getLabel"
					type="primary"
					@click="submitData"
				/>
			</span>
		</template>
	</DialogPV>
</template>

<script>
import {
	ElMessageBox
} from 'element-plus';
import { mapActions } from 'vuex';
import DialogPV from 'primevue/dialog';
import ButtonPV from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import RadioButton from 'primevue/radiobutton';
import InputText from 'primevue/inputtext';

export default {
	components: {
		ButtonPV,
		InputText,
		RadioButton,
		ToggleButton,
		DialogPV
	},
	data() {
		return {
			dialogVisible: true,
			email: '',
			password: '',
			workTimeVal: '',
			singInWorkTime: true,
			radio: 'sw'
		};
	},
	computed: {
		getLabel() {
			return this.singInWorkTime ? 'Sign-In' : 'Submit';
		}
	},
	methods: {
		...mapActions('base', ['LogIn', 'workTime']),
		submitData() {
			if (this.singInWorkTime) this.LogIn({ email: this.email, password: this.password });
			else this.workTime({ accCode: this.workTimeVal, radio: this.radio });
		},
		handleClose() {
			ElMessageBox.alert('Log in and the window will close.')
				.then(() => {
					this.dialogVisible = true;
				})
				.catch(() => {
					// catch error
				});
		}
	}
};
</script>

<template>
	<DialogPV
		v-model:visible="showDismissEmp"
		width="500px"
		header="Employe cashadvance">
		<span>
			<emp-select
				widthclass="w430"
			/>
		</span>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					label="Cancel"
					class="me-3"
					severity="danger"
					@click="setDismissEmp({ b: false })" />
				<ButtonPV
					type="primary"
					:disabled="validateData"
					label="Dismiss worker"
					@click="DismissEmp({ id: this.getEmployeFromSelect });" />
			</span>
		</template>
	</DialogPV>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
// eslint-disable-next-line import/no-unresolved
import EmpSelect from '@/components/other/EmpSelect.vue';

export default {
	components: {
		EmpSelect,
		ButtonPV,
		DialogPV
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getDismissEmp',
				'getEmployeFromSelect'
			]
		),
		showDismissEmp: {
			get() {
				return this.getDismissEmp;
			},
			set(v) {
				if (!v) this.setEmployeFromSelect('');
				this.setDismissEmp({ b: v });
			}
		},
		validateData() {
			if (this.getEmployeFromSelect === '') return true;
			return false;
		}
	},
	methods: {
		...mapActions('nimdaEmp', ['DismissEmp', 'setEmployeFromSelect']),
		...mapMutations('nimdaEmp',	['setDismissEmp'])
	}
};
</script>>
<style scoped>
.scrollable {
	overflow: hidden;
	overflow-y: scroll;
	height: 350px;
	width: 720px;
	border-style: solid;
	border-width: 1px;
}
.info-div {
	height: 350px;
	width: 200px;
	border-style: solid;
	border-width: 1px;
	padding: 5px;
}
</style>

<template>
	<Drawer
		v-model:visible="staticDrawer"
		title="Statistic"
		position="bottom"
		style="height: auto"
	>
		<div v-if="getStatisticReady">
			<div
				v-for="category of categories"
				:key="category.key"
				class="flex items-center">
				<Checkbox
					v-model="selectedCategories"
					:inputId="category.key"
					name="category"
					:value="category.name" />
				<label
					:for="category.key"
					class="set-font ms-2 me-4 mb-2 mt-3">{{ category.name }}</label>
			</div>
			<DataTable
				v-model:selection="selectedProduct"
				:value="getStatisticArr"
				style="width: 100%"
				showGridlines
				class="mt-3"
			>
				<ColumnGroup type="header">
					<Row>
						<Column
							field="goal"
							:rowspan="2"
							style="background-color:black;"
							header="Goal" />
						<Column
							header="USD"
							style="background-color:black;"
							:colspan="2" />
						<Column
							headerStyle="text-align: center"
							style="background-color:black;"
							header="USDT"
							:colspan="2" />
						<Column
							header="PHP"
							style="background-color:black;"
							:colspan="2" />
						<Column
							header="RUR"
							style="background-color:black;"
							:colspan="2" />
						<Column
							selectionMode="multiple"
							style="background-color:black;"
							:rowspan="2" />
					</Row>
					<Row>
						<Column
							field="USDSpent"
							style="background-color:black;"
							sortable
							header="Spent" />
						<Column
							field="USDCredit"
							style="background-color:black;"
							sortable
							header="Credit" />
						<Column
							field="USDSpent"
							style="background-color:black;"
							sortable
							header="Spent" />
						<Column
							field="USDTCredit"
							style="background-color:black;"
							sortable
							header="Credit" />
						<Column
							field="PHPSpent"
							style="background-color:black;"
							sortable
							header="Spent" />
						<Column
							field="PHPCredit"
							style="background-color:black;"
							sortable
							header="Credit" />
						<Column
							field="RUBSpent"
							style="background-color:black;"
							sortable
							header="Spent" />
						<Column
							field="RUBCredit"
							style="background-color:black;"
							sortable
							header="Credit" />
					</Row>
				</ColumnGroup>
				<Column field="goal" />
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.USDSpent }}$ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.USDCredit }}$ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.USDTSpent }}$ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.USDTCredit }}$ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.PHPSpent }}₱ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.PHPCredit }}₱ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.RUBSpent }}₽ </template>
				</Column>
				<Column field="lastYearSale">
					<template #body="slotProps"> {{ slotProps.data.RUBCredit }}₽ </template>
				</Column>
				<Column selectionMode="multiple" />
				<ColumnGroup type="footer">
					<Row>
						<Column
							footer="Totals:"
							:colspan="1"
							footerStyle="text-align: left"
							style="background-color:black;" />
						<Column
							:footer="USDSpentTotal"
							style="background-color:black;" />
						<Column
							:footer="USDCreditTotal"
							style="background-color:black;" />
						<Column
							:footer="USDTSpentTotal"
							style="background-color:black;" />
						<Column
							:footer="USDTCreditTotal"
							style="background-color:black;" />
						<Column
							:footer="PHPSpentTotal"
							style="background-color:black;" />
						<Column
							:footer="PHPCreditTotal"
							style="background-color:black;" />
						<Column
							:footer="RUBSpentTotal"
							style="background-color:black;" />
						<Column
							:footer="RUBCreditTotal"
							style="background-color:black;" />
						<Column style="background-color:black;" />
					</Row>
					<Row>
						<Column
							footer="Difference:"
							style="background-color:black;"
							footerStyle="text-align: left" />
						<Column
							:footer="USDProf"
							style="background-color:black;"
							:colspan="2" />
						<Column
							:footer="USDTProf"
							style="background-color:black;"
							:colspan="2" />
						<Column
							:footer="PHPProf"
							style="background-color:black;"
							:colspan="2" />
						<Column
							:footer="RUBProf"
							style="background-color:black;"
							:colspan="2" />
						<Column style="background-color:black;" />
					</Row>
					<Row>
						<Column
							footer="Selected:"
							style="background-color:darkslategrey;"
							:colspan="1"
							footerStyle="text-align: left" />
						<Column
							:footer="USDSpentSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="USDCreditSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="USDTSpentSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="USDTCreditSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="PHPSpentSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="PHPCreditSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="RUBSpentSel"
							style="background-color:darkslategrey;" />
						<Column
							:footer="RUBCreditSel"
							style="background-color:darkslategrey;" />
						<Column style="background-color:darkslategrey;" />
					</Row>
				</ColumnGroup>
			</DataTable>
		</div>
	</Drawer>
</template>
<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Drawer from 'primevue/drawer';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row'; // optional

// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';

export default {
	components: {
		Checkbox,
		DataTable,
		Drawer,
		Column,
		Row,
		ColumnGroup
	},
	data() {
		return {
			selectedProduct: null,
			metaKey: true,
			categories: [
				{ name: 'Personal', key: 'P' },
				{ name: 'Business', key: 'B' }
			]
		};
	},
	computed: {
		...mapGetters(
			'accaunt',
			[
				'getStaticDrawer',
				'getStatisticReady',
				'getStatisticItogoReady',
				'getSelectedStatic',
				'getStatisticArr'
			]
		),
		...mapGetters(
			'base',
			[
				'getValuta'
			]
		),
		selectedCategories: {
			get() {
				return this.getSelectedStatic;
			},
			set(val) {
				this.setSelectedStatic(val);
				this.statistic();
			}
		},
		staticDrawer: {
			get() {
				return this.getStaticDrawer;
			},
			set(value) {
				this.setStaticDrawer(value);
			}
		},
		PHPSpentSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00₱';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.PHPSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₱`;
		},
		PHPCreditSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00₱';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.PHPCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₱`;
		},
		USDSpentSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00$';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.USDSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDCreditSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00$';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.USDCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDTSpentSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00$';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.USDTSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDTCreditSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00$';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.USDTCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		RUBSpentSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00₱';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.RUBSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₽`;
		},
		RUBCreditSel() {
			let total = 0;
			if (!this.selectedProduct) return '0.00₱';
			for (const item of this.selectedProduct) {
				total += utils.DoNumber(item.RUBCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₽`;
		},
		PHPSpentTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.PHPSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₱`;
		},
		PHPCreditTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.PHPCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₱`;
		},
		RUBSpentTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.RUBSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₽`;
		},
		RUBCreditTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.RUBCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}₽`;
		},
		USDSpentTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.USDSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDCreditTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.USDCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDTSpentTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.USDTSpent);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDTCreditTotal() {
			let total = 0;
			for (const item of this.getStatisticArr) {
				total += utils.DoNumber(item.USDTCredit);
			}
			return `${utils.number_format(total, 2, '.', ' ')}$`;
		},
		USDProf() {
			let totalS = 0;
			let totalC = 0;
			for (const item of this.getStatisticArr) {
				totalS += utils.DoNumber(item.USDSpent);
				totalC += utils.DoNumber(item.USDCredit);
			}
			return `${utils.number_format(totalC - totalS, 2, '.', ' ')}₱`;
		},
		PHPProf() {
			let totalS = 0;
			let totalC = 0;
			for (const item of this.getStatisticArr) {
				totalS += utils.DoNumber(item.PHPSpent);
				totalC += utils.DoNumber(item.PHPCredit);
			}
			return `${utils.number_format(totalC - totalS, 2, '.', ' ')}₱`;
		},
		USDTProf() {
			let totalS = 0;
			let totalC = 0;
			for (const item of this.getStatisticArr) {
				totalS += utils.DoNumber(item.USDTSpent);
				totalC += utils.DoNumber(item.USDTCredit);
			}
			return `${utils.number_format(totalC - totalS, 2, '.', ' ')}₱`;
		},
		RUBProf() {
			let totalS = 0;
			let totalC = 0;
			for (const item of this.getStatisticArr) {
				totalS += utils.DoNumber(item.RUBSpent);
				totalC += utils.DoNumber(item.RUBCredit);
			}
			return `${utils.number_format(totalC - totalS, 2, '.', ' ')}₽`;
		}
	},
	methods: {
		...mapMutations(
			'accaunt',
			[
				'setStaticDrawer',
				'setSelectedStatic'
			]
		),
		...mapActions(
			'accaunt',
			[
				'statistic',
				'statisticItogo'
			]
		)
	}
};
</script>
<style scoped>
.selRow {
	background-color:darkslategrey;
}
</style>

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-cycle */
import { createStore } from 'vuex';

import base from '@/store/base';
import accaunt from '@/store/accaunt';
import nimda from '@/store/nimda';
import nimdaEmp from '@/store/nimdaEmp';
import nimdaMain from '@/store/nimdaMain';
import nimdaConnect from '@/store/nimdaConnect';
import nimdaUser from '@/store/nimdaUser';
import { addResponseHandler } from '@/api/http';

const store = createStore({
	modules: {
		base: base,
		nimdaMain: nimdaMain,
		nimdaUser: nimdaUser,
		nimdaConnect: nimdaConnect,
		accaunt: accaunt,
		nimda: nimda,
		nimdaEmp: nimdaEmp
	}
	/// strict: process.env.NODE_ENV !== 'production'
});

addResponseHandler(
	(response) => {
		if (response.data.data !== undefined) {
			const item = response.data.data.find(element => element.error === 'error' || element.error === 'warning');
			if (item !== undefined) {
				store.dispatch('base/handError', {
					res: item.problem,
					prob: item.result,
					err: item.error,
					reload: false,
					html: true
				}, { root: true });
			}
		}
		if (response.data.error === 'info') {
			store.dispatch('base/handError', {
				res: response.data.problem,
				prob: response.data.result,
				err: response.data.error,
				reload: false,
				html: true
			}, { root: true });
		}
		if (response.data.error !== 0 && response.data.error !== 'info') {
			store.dispatch('base/handError', {
				res: response.data.problem,
				prob: response.data.result,
				err: response.data.error,
				reload: false,
				html: true
			}, { root: true });
			return {
				data:
					{ error: 99999 }
			};
		}
		return response;
	},
	(error) => {
		const { config } = error.response;
		console.log(config);
		if (config.error !== 0 && config.error === 'info') {
			const str = 'try to reload page';
			store.dispatch('base/handError', {
				res: `${error.message}, ${str}`, prob: error.code, err: config.error, reload: true, html: true
			}, { root: true });
			return {
				data:
					{ error: 99999 }
			};
		}
		return Promise.reject(error);
	}
);

export default store;

import { createApp } from 'vue';

// eslint-disable-next-line import/no-extraneous-dependencies
import PrimeVue from 'primevue/config';
// eslint-disable-next-line import/no-extraneous-dependencies
import Aura from '@primevue/themes/aura';

import './main.css';

import 'element-plus/es/components/dialog/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/upload/style/css';

// import print from 'vue3-print-nb';
import router from './router';
import store from './store';
import App from './App.vue';

const app = createApp(App);

app.use(PrimeVue, {
	theme: {
		preset: Aura,
		options: {
			cssLayer: {
				name: 'primevue',
				order: 'tailwind-base, primevue, tailwind-utilities'
			},
			prefix: 'p',
			darkModeSelector: 'system'
		}
	}
});

app.use(router);
app.use(store);
// app.use(print);
app.mount('#app');

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
// 	app.component(key, component);
// }

export default app;

<template>
	<!-- <div class="card flex flex-col gap-6 items-center justify-center">
		<FileUpload
			ref="fileupload"
			mode="basic"
			name="demo[]"
			:url="baseUrl"
			:maxFileSize="1000000"
			@upload="onUpload" />
		<ButtonPV
			label="Upload"
			severity="secondary"
			@click="upload" />
	</div> -->
	<el-upload
		ref="uploadRef"
		class="upload-demo"
		:action="baseUrl"
		:on-success="successHandle"
		:auto-upload="false"
		:limit="3"
	>
		<template #trigger>
			<ButtonPV
				class="mt-3 ms-3"
				label="Select file"
				type="primary" />
		</template>
		<ButtonPV
			class="ms-3"
			type="success"
			label="Upload to server"
			@click="submitUpload" />
		<template #tip>
			<div class="mt-3">
				xlsx file with a size less than 500kb
			</div>
		</template>
	</el-upload>
</template>

<script>

import { ElUpload } from 'element-plus';
import { mapActions } from 'vuex';
// import FileUpload from 'primevue/fileupload';
import ButtonPV from 'primevue/button';
import { baseUploadUrl } from '../../store/constants';

// import nimdaConnect from '@/store/nimdaConnect';

export default {
	components: {
		// FileUpload,
		ElUpload,
		ButtonPV
	},
	data() {
		return {
			uploadRef: null,
			baseUrl: ''
		};
	},
	methods: {
		...mapActions('nimdaConnect', ['loadCustomersData']),
		successHandle() {
			this.loadCustomersData();
		},
		submitUpload() {
			this.uploadRef.submit();
		},
		upload() {
			console.log(this.$refs.fileupload);
			this.$refs.fileupload.upload();
		},
		onUpload() {
			this.$toast.add({
				severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000
			});
		}
	},
	mounted() {
		this.uploadRef = this.$refs.uploadRef;
		this.baseUrl = baseUploadUrl;
		console.log(this.baseUrl);
	}
};
</script>
<style scoped>
.upload-demo {
	margin: 10px;
}
</style>

/* eslint-disable import/extensions */
import { ElMessage } from 'element-plus';
import { encode } from 'js-base64';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-unresolved
import router from '@/router';
// eslint-disable-next-line import/no-unresolved
import utils from '@/utils/utils';
// eslint-disable-next-line import/no-cycle
import * as baseApi from '../api/base';

const sha256 = require('js-sha256');

export default {
	namespaced: true,
	state: {
		globeError: 0,
		userType: 0,
		token: null,
		user: '',
		userId: 0,
		registered: false,
		menu: [],
		isMenuLoaded: false,
		mainScreenSize: 0,
		mainAccMenuSize: 0,
		valuta: []
	},
	getters: {
		getUserType: state => state.userType,
		getUserId: state => state.userId,
		getUser: state => state.user,
		getToken: (state) => {
			if (state.token) return state.token;
			const tmpToken = localStorage.getItem('FDIToken');
			if (tmpToken) state.token = tmpToken;
			return state.token;
		},
		getReg: state => state.registered,
		getMenu: state => state.menu,
		getValuta: state => state.valuta,
		getGlobError: state => state.globeError,
		getMenuLoad: state => state.isMenuLoaded,
		getMainScreenSizeY: state => state.mainScreenSizeY,
		getMainScreenSizeX: state => state.mainScreenSizeX
	},
	mutations: {
		setGlobError: function (state, err) {
			state.globeError = err;
		},
		setUser: function (state, user) {
			state.user = user;
		},
		setValuta: function (state, val) {
			state.valuta = val;
		},
		setUserType: function (state, userType) {
			state.userType = userType;
		},
		setToken: function (state, token) {
			state.token = token;
			localStorage.setItem('FDIToken', token);
		},
		setRegistered: function (state, reg) {
			state.registered = reg;
		},
		setMenu: function (state, menu) {
			state.menu = menu;
		},
		setMenuLoad: function (state, isMenu) {
			state.isMenuLoaded = isMenu;
		},
		setmainScreenSizeY: function (state, sizeY) {
			state.mainScreenSizeY = sizeY;
		},
		setmainScreenSizeX: function (state, sizeX) {
			state.mainScreenSizeX = sizeX;
		},
		setUserId: function (state, id) {
			state.userId = id;
		}
	},
	actions: {
		setmainScreenSize: function ({ commit }, { sizeY, sizeX }) {
			commit('setmainScreenSizeY', sizeY);
			commit('setmainScreenSizeX', sizeX);
		},
		setUserData: function ({ commit }, {
			user, userType, token, id
		}) {
			if (token === '') return;
			commit('setUser', user);
			commit('setUserType', userType);
			if (token !== '') commit('setToken', token);
			commit('setUserId', id);
			if (token !== '') {
				commit('setRegistered', true);
			} else {
				commit('setRegistered', false);
			}
		},
		chkToken: async function ({ commit, dispatch }) {
			const response = await baseApi.chkToken();
			if (response.data.error === 0) {
				commit('setGlobError', 0);
				await dispatch('setUserData', {
					user: response.data.name,
					userType: response.data.userType,
					token: '',
					id: response.data.id
				});
				await commit('setRegistered', true);
			}
		},
		LogIn: async function ({ commit, dispatch }, { email, password }) {
			const response = await baseApi.LogIn({ email: encode(email), password: sha256(password) });
			if (response.data.error === 0) {
				commit('setGlobError', 0);
				await dispatch('setUserData', {
					user: response.data.name,
					userType: response.data.userType,
					token: response.data.token,
					id: response.data.id
				});
				await dispatch('loadMenu');
				router.push({ name: 'accaunt' });
			}
		},
		workTime: async function ({ dispatch, rootGetters }, { accCode, radio }) {
			if (accCode === '') return;
			const response = await baseApi.workTime({ accCode: accCode, timeOfDay: radio });
			if (response.data.error === 0 || response.data.error === 'info') {
				if (response.data.timeOfDay === 'sw') {
					dispatch('nimdaEmp/addEmployeeSalaryByDay', response.data.row, { root: true });
					console.log('7777999997', rootGetters['nimdaEmp/getEmpSalaryByToDate'](response.data.row.employeId));
				} else {
					console.log('77777', rootGetters['nimdaEmp/getEmpSalaryByToDate'](response.data.row.employeId));
				}
			}
		},
		loadMenu: async function ({ getters, commit }) {
			if (getters.getGlobError !== 0 && getters.getMenuLoad) return;
			const response = await baseApi.loadMenu();
			if (response.data.error === 0) {
				commit('setMenu', response.data.menu);
				commit('setValuta', response.data.valuta);
				await commit('setMenuLoad', true);
			}
			console.log('getters.getGlobError', getters.getGlobError);
		},
		exitFromApp: async function () {
			const response = await baseApi.exit();
			if (response.data.error === 0) {
				if (response.data.data[0].mes === 'deleted') return true;
			}
			return false;
		},
		handError: function ({ commit }, {
			res,
			prob,
			err,
			reload,
			html
		}) {
			let reloadStr = '';
			if (reload) reloadStr = '<a href="#" @click="window.location.location"> reload page?</a>';
			ElMessage({
				type: err,
				message: `${utils.splitStr(prob)}.<br /> ${utils.splitStr(res)}.<br /> ${reloadStr}`,
				showClose: true,
				duration: 10000,
				dangerouslyUseHTMLString: html
			});
			if (res === 'exp' || res === 'gerror') {
				commit('setToken', '');
				commit('setGlobError', 1);
				router.push({ name: 'login' });
			}
		}
	}
};

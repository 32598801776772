<template>
	<SelectPV
		v-model="value"
		class="m-2 test"
		:class="widthClass"
		optionValue="id"
		optionLabel="label"
		:options="getEmployees"
		placeholder="Please select"
		size="large"
	/>
	<validate-sing
		:validateVal="value <= 0"
		:marginTop="margintop"
	/>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SelectPV from 'primevue/select';
import ValidateSing from './ValidateSing.vue';

export default {
	components: {
		SelectPV,
		ValidateSing
	},
	props: {
		widthclass: { type: String, default: '' },
		margintop: { type: Number, default: 1 }
	},
	computed: {
		...mapGetters('nimdaEmp', ['getEmployees', 'getEmployeFromSelect']),
		value: {
			get: function () {
				return this.getEmployeFromSelect;
			},
			set: function (value) {
				this.setEmployeFromSelect(value);
			}
		},
		widthClass() {
			if (this.widthclass !== '') return this.widthclass;
			return 'w220';
		}
	},
	methods: {
		...mapActions('nimdaEmp', ['setEmployeFromSelect'])
	}
};
</script>

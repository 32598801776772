<template>
	<div>
		<h1>Page not found</h1>
		<hr>
		Start from main page
	</div>
</template>

<script>
export default {

};
</script>

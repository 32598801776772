// import { ElMessage, ElMessageBox } from 'element-plus';
// eslint-disable-next-line import/extensions, import/no-unresolved
// import utils from '@/utils/utils';
// eslint-disable-next-line import/no-cycle, import/extensions, import/no-unresolved
import * as nimdaConnectApi from '@/api/nimdaConnect';

export default {
	namespaced: true,
	state: {
		customers: [] // список клиентов грузится по требованию
	},
	getters: {
		getCustomersArr: state => state.customers
	},
	mutations: {
		setCustomersArr(state, v) {
			state.customers = v;
			console.log('response', state.customers);
		}
	},
	actions: {
		async loadCustomersData({ rootGetters, commit }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await nimdaConnectApi.loadCustomersData();
			if (response.data.error === 0) {
				commit('setCustomersArr', response.data.rows);
			}
		}
	}
};

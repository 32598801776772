<template>
	<div class="card mt-3">
		<DataTable
			v-model:filters="filters"
			:value="getDataForTable"
			editMode="cell"
			:pt="{
				table: { style: 'min-width: 40rem' },
				column: {
					bodycell: ({ state }) => ({
						class: [{ 'pt-0 pb-0': state.d_editing }],
					}),
				},
			}"
			@cell-edit-complete="onCellClick"
		>
			<template #header>
				<div class="flex justify-end">
					<IconField>
						<InputIcon>
							<i class="pi pi-search me-2" />
						</InputIcon>
						<InputText
							v-model="filters.global.value"
							placeholder="Keyword Search" />
					</IconField>
				</div>
			</template>
			<Column
				v-for="col of getAccauntGridHead"
				:key="col.field"
				:field="col.field"
				:sortable="col.sortable"
				:header="col.header"
				style="width: 10%">
				<template #body="{ data, field }">
					{{ field === 'amount' ? test(data[field]) : data[field] }}
				</template>
				<template #editor="{ data, field }">
					<DatePicker
						v-if="field === 'dateofpayment'"
						v-model="data[field]"
						fluid
						dateFormat="dd-mm-yy"
						showButtonBar />
					<SelectPV
						v-if="field === 'creditspent'"
						v-model="selectedSpentCredit"
						:options="spentCredit"
						optionLabel="name"
						placeholder="Credit/Spent"
						class="w-full md:w-56" />
					<SelectPV
						v-if="field === 'goal'"
						v-model="selectedGoal"
						:options="goalArr"
						optionLabel="name"
						placeholder="Credit/Spent"
						class="w-full md:w-56" />
					<SelectPV
						v-if="field === 'derection'"
						v-model="selectedPersonalArr"
						:options="personalArr"
						optionLabel="name"
						autofocus
						placeholder="Credit/Spent"
						class="w-full md:w-56" />
					<SelectPV
						v-if="field === 'valuta'"
						v-model="selectedValuta"
						:options="valutaArr"
						optionLabel="name"
						autofocus
						placeholder="Credit/Spent"
						class="w-full md:w-56" />
					<InputText
						v-if="field === 'amount'"
						v-model="data[field]"
						autofocus
						fluid />
					<InputText
						v-if="field === 'walletamount'"
						v-model="data[field]"
						disabled
						autofocus
						fluid />
					<InputText
						v-if="field === 'note'"
						v-model="data[field]"
						autofocus
						fluid />
				</template>
			</Column>
		</DataTable>
	</div>
</template>
<script>

// import { h, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import DatePicker from 'primevue/datepicker';
import SelectPV from 'primevue/select';

// eslint-disable-next-line import/no-duplicates
// import InputNumber from 'primevue/inputnumber';

import { FilterMatchMode } from '@primevue/core/api';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';

export default {
	components: {
		DataTable,
		SelectPV,
		DatePicker,
		Column,
		InputText
	},
	props: {
		data: { type: Array, required: true },
		height: { type: Number, default: 20 },
		rowClass: { type: String, default: '' }
	},
	data() {
		return {
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
				representative: { value: null, matchMode: FilterMatchMode.IN },
				status: { value: null, matchMode: FilterMatchMode.EQUALS },
				verified: { value: null, matchMode: FilterMatchMode.EQUALS }
			},
			selectedSpentCredit: null,
			spentCredit: [
				{ name: 'Spent', code: 'Spent' },
				{ name: 'Credit', code: 'Credit' }
			],
			selectedGoal: null,
			goalArr: [
				{ name: 'Hotel', code: 'Hotel' },
				{ name: 'BAR/Restaurant', code: 'BAR/Restaurant' },
				{ name: 'Salary', code: 'Salary' },
				{ name: 'Household goods/construction', code: 'Household goods/construction' },
				{ name: 'Freediving/Snorkeling', code: 'Freediving/Snorkeling' },
				{ name: 'Advertising', code: 'Advertising' },
				{ name: 'Large purchases', code: 'Large purchases' },
				{ name: 'Mobile/Internet', code: 'Mobile/Internet' },
				{ name: 'Permits/accounting/BRI', code: 'Permits/accounting/BRI' },
				{ name: 'Rent/OutSourcing', code: 'Rent/OutSourcing' },
				{ name: 'Investment', code: 'Investment' }],
			selectedPersonalArr: null,
			personalArr: [
				{ name: 'Business', code: 'Business' },
				{ name: 'Personal', code: 'Personal' }
			],
			selectedValuta: null,
			valutaArr: [
				{ name: 'PHP', code: 'PHP' },
				{ name: 'USD', code: 'USD' },
				{ name: 'USDT', code: 'USDT' },
				{ name: 'RUR', code: 'RUR' }
			],
			selectedCell: null,
			inputValue: '',
			inputAmauntValue: '',
			search: '',
			searchNote: '',
			rowHandlers: {
				onClick: this.onRowClick
			}
		};
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeX']),
		...mapGetters(
			'accaunt',
			[
				'getAccauntGridHead',
				'getsaveResult',
				'getsaveMes',
				'getShowDeleteColum',
				'getGridData'
			]
		),
		getOptionsCS() {
			return this.getAccauntGridHead.find(el => el.key === 'creditspent').addData;
		},
		getDataForTable() {
			let filteredData = this.data;
			if (this.inputValue !== '') {
				filteredData = filteredData.filter(
					el => el.note.toUpperCase().includes(this.inputValue.toUpperCase())
				);
			}
			if (this.inputAmauntValue !== '') {
				filteredData = filteredData.filter(
					el => el.amount.toUpperCase().includes(this.inputAmauntValue.toUpperCase())
				);
			}
			return filteredData;
		}
	},
	methods: {
		...mapActions('accaunt', ['deleteAccauntGridData', 'updateGridData']),
		test(v) {
			return utils.number_format(v, 2, '.', ' ');
		},
		isPositiveInteger(val) {
			let str = String(val);

			str = str.trim();

			if (!str) {
				return false;
			}

			str = str.replace(/^0+/, '') || '0';
			const n = Math.floor(Number(str));

			return n !== Infinity && String(n) === str && n >= 0;
		},
		formatCurrency(value) {
			return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
		},
		onCellClick(event) {
			const {
				data, newValue, field
			} = event;
			data.edited = true;
			let numEl;
			if (data[field] === '') numEl = parseFloat(newValue.match(/\d+\.*\d*/));
			if (String(newValue).trim().length > 0) {
				switch (field) {
				case 'note':
					data[field] = newValue;
					if (newValue.includes('-')) data.creditspent = 'Spent';
					if (newValue.includes('+')) data.creditspent = 'Credit';
					if (!Number.isNaN(numEl) && data.amount === 0) {
						data.amount = numEl;
					}
					if (newValue.includes('USD') || newValue.includes('usd') || newValue.includes('$')) data.valuta = 'USD';
					if (newValue.includes('hotel') || utils.includesIgnoreCase(newValue, 'airbnb')) data.goal = 'Hotel';
					if (newValue.includes('freediving')) data.goal = 'Freediving/Snorkeling';
					break;
				case 'creditspent':
					data[field] = this.selectedSpentCredit.name;
					break;
				case 'derection':
					data[field] = this.selectedPersonalArr.name;
					break;
				case 'goal':
					data[field] = this.selectedGoal.name;
					break;
				case 'amount': data[field] = utils.CalcAll(newValue);
					break;
				case 'valuta':
					data[field] = this.selectedValuta.name;
					break;
				case 'dateofpayment':
					data[field] = utils.getDate(newValue);
					break;
				case 'dateofrecord':
					data[field] = utils.getDate(newValue);
					break;
				default:
				}
			} else event.preventDefault();
		},
		formatDate(dateString) {
			const dateParts = dateString.split('-');
			return new Date(dateParts[2], dateParts[1], dateParts[0], 3, 0, 0, 0);
		}
	}
};

</script>

<style>
	.w300 {
		width: 370px;
	}
	.test {
		scroll-behavior: auto;
	}
	.warning-row {
	background-color: var(--el-color-warning-light-8);
	}

	.exchage-row {
	background-color: var(--el-color-success-16);
	}

	.wallet-row {
	background-color: var(--el-color-success);
	}

	.danger-row {
	background-color: var(--el-color-danger-light-8);
	}

	.primary-row {
	background-color: var(--el-color-primary-light-8);
	}

	.success-row {
	background-color: var(--el-color-success-light-8);
	}
</style>

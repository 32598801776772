<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
	<accaunt-side-menu />
	<div class="elem-block-loc">
		<transition
			appear
			name="fademar"
			mode="out-in"
		>
			<div
				class="menu-l-smoll"
				:style="{ height: `${getMainScreenSizeY}px` }"
			>
				<p
					class="pi pi-th-large mt-4 ms-2"
					style="font-size: 1.5rem"
					@click="setOpenCloseDraver" />
			</div>
		</transition>
		<div
			v-if="getAccauntTableLoaded"
			:style="{ width: `${getMainScreenSizeX - 50}px` }"
			class="gid-table"
		>
			<accaunt-table
				:data="getAccauntGridData"
				:height="getMainScreenSizeY"
			/>
		</div>
	</div>
	<accaunt-statistic-drawer />
	<accaunt-exc-dialog />
	<acc-balances-wallets />
</template>

<script>

// eslint-disable-next-line import/no-extraneous-dependencies
import 'primeicons/primeicons.css';
import { mapActions, mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import AccauntSideMenu from '@/components/Accaunt/AccSideMenuComp.vue';
// eslint-disable-next-line import/no-unresolved
import AccauntStatisticDrawer from '@/components/Accaunt/AccStatisticDrawerComp.vue';
// eslint-disable-next-line import/no-unresolved
import AccauntExcDialog from '@/components/Accaunt/AccExcDialogComp.vue';
// eslint-disable-next-line import/no-unresolved
import AccauntTable from '@/components/Accaunt/AccTableComp.vue';
// eslint-disable-next-line import/no-unresolved
import AccBalancesWallets from '@/components/Accaunt/AccBalancesWalletsComp.vue';

export default {
	components: {
		AccauntSideMenu,
		AccauntStatisticDrawer,
		AccauntExcDialog,
		AccauntTable,
		AccBalancesWallets
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeY', 'getMainScreenSizeX']),
		...mapGetters(
			'accaunt',
			[
				'getAccauntGridData',
				'getAccauntTableLoaded',
				'getDays'
			]
		)
	},
	methods: {
		...mapMutations(
			'accaunt',
			[
				'setOpenCloseDraver'
			]
		),
		...mapActions(
			'accaunt',
			[
				'loadAccauntGridHead',
				'getMunuPoint',
				'loadAccauntGridData',
				'loadBalanceOnWallet'
			]
		),
		escapeListener(event) {
			if (event.keyCode === 65 && event.altKey) this.getMunuPoint({ menuName: 'add' });
			if (event.keyCode === 83 && event.altKey) this.getMunuPoint({ menuName: 'save' });
		}
	},
	mounted() {
		if (!this.getAccauntTableLoaded) {
			this.loadAccauntGridHead().then(() => this.loadAccauntGridData({ task: '20days', days: 20 }));
			this.loadBalanceOnWallet();
		}
	},
	created() {
		window.addEventListener('keydown', this.escapeListener);
	},
	unmounted() {
		window.removeEventListener('keydown', this.escapeListener);
	}
};
</script>

<style scoped>
.menu-l-smoll {
	width: 42px;
}
.elem-block-loc {
	display: flex;
}
</style>

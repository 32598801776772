<template>
	<DialogPV
		v-model:visible="showDialog"
		style="width: 980px"
		title="Edit Employer">
		<p
			v-if="getEmpEditId === 0">
			Date of employment
			<DatePicker
				v-model="empEmploymentDate"
				type="date"
				placeholder="Pick a day"
			/>
			<validate-sing
				:validateVal="!chkEmpEmploymentDate"
				:marginTop="2" />
		</p>
		<div class="scrollable">
			<div class="ms-0 border">
				<div
					class="w100pr mt-2 ms-3 mb-0"
				>
					Name
				</div>
				<InputText
					v-model="empName"
					class="w250 ms-3 mt-0 mb-0"
				/>
				<validate-sing
					:validateVal="!chkName"
					:marginTop="4" />
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-2 ms-3 mb-0">
					Middle Name
				</div>
				<InputText
					v-model="empMiddleName"
					class="w250 ms-3 mt-0 mb-0"
				/>
				<validate-sing
					:validateVal="!chkMidlName"
					:marginTop="4" />
			</div>
			<div
				class="ms-0 border border"
				style="border-right: 0;">
				<div
					class="w100pr mt-2 ms-3 mb-0"
				>
					Last name
				</div>
				<InputText
					v-model="empLastName"
					class="w250 ms-3 mt-0 mb-0"
				/>
				<validate-sing
					:validateVal="!chkLastName"
					:marginTop="4" />
			</div>
			<div class="ms-0 border">
				<div
					class="w100pr mt-2 ms-3 mb-0"
					style="border-bottom: 0;"
				>
					Salary Rate
				</div>
				<InputNumber
					v-model="empSalaryRate"
					class="w250 ms-3 mt-0 mb-0"
				/>
				<validate-sing
					:validateVal="!chkSalaryRate"
					:marginTop="4" />
			</div>
			<div
				class="ms-0 border"
				style="border-bottom: 0;">
				<div
					class="w100pr mt-2 ms-3 mb-0"
				>
					Salary period
				</div>
				<SelectPV
					v-model="empSalaryPeriod"
					placeholder="Select"
					class="w250 ms-3 mt-0 mb-0"
					:options="getEmpSalaryCycle"
					optionLabel="label"
					optionValue="value"
				/>
				<validate-sing
					:validateVal="!chkSalaryPeriod"
					:marginTop="4" />
			</div>
			<div class="ms-0 border border-last">
				<div
					class="w100pr mt-2 ms-3 mb-0"
				>
					Extra Time Salary
				</div>
				<InputNumber
					v-model="empExtraSalary"
					class="w250 ms-3 mt-0 mb-0"
				/>
				<validate-sing
					:validateVal="!chkExtraSalary"
					:marginTop="4" />
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					label="Cancel"
					@click="setShowEmpEditDial({ edit: false, id: 0 })" />
				<ButtonPV
					type="primary"
					:disabled="!chkAll"
					class="ms-2"
					label="Save"
					@click="saveEmploye(emp);" />
			</span>
		</template>
	</DialogPV>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePicker from 'primevue/datepicker';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import SelectPV from 'primevue/select';
// eslint-disable-next-line import/no-unresolved
import ValidateSing from '@/components/other/ValidateSing.vue';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils.js';

export default {
	components: {
		DialogPV,
		ButtonPV,
		DatePicker,
		InputNumber,
		InputText,
		ValidateSing,
		SelectPV
	},
	data() {
		return {
			emp: {
				id: 0,
				name: '',
				midlName: '',
				lastName: '',
				cashAdvance: 0,
				timeOfLastUpdateD: 0,
				timeOfJobStart: 0,
				accCode: 0,
				Dismissed: 0,
				DismissedDate: 0,
				lunchBreak: 0,
				timeOfJobStartD: 0,
				salary: 0,
				extraTimeSalary: 0,
				saved: false,
				perDayOrHours: 'Day'
			}
		};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getDialogCalcSalary',
				'getEmployeesById',
				'getDialogCalcId',
				'getEmployeesSalary',
				'getShowEmpEditDial',
				'CalcSumSalary',
				'CalcDates',
				'CalcDays',
				'CalcHours',
				'CalcMin',
				'getEmployeCashAdvance',
				'CalcAllSecond',
				'getEmployeNewCashAdvance',
				'getEmpSalaryCycleId',
				'getEmpSalaryCycle',
				'getEmpEditId',
				'getEmployeDeducted'
			]
		),
		chkAll() {
			let d;
			if (this.getEmpEditId === 0) d = this.chkEmpEmploymentDate;
			else d = true;
			return d
				&& this.chkName
				&& this.chkMidlName
				&& this.chkLastName
				&& this.chkLastName
				&& this.chkSalaryRate
				&& this.chkExtraSalary
				&& this.chkSalaryPeriod;
		},
		chkEmpEmploymentDate() {
			if (this.getEmpEditId === 0) return utils.chkDate(this.emp.timeOfJobStartD, 7);
			const { timeOfJobStartD } = this.getEmployeesById(this.getEmpEditId);
			return utils.chkDate(timeOfJobStartD, 7);
		},
		chkName() {
			const { name } = this.getEmployeesById(this.getEmpEditId);
			if (name === undefined) return utils.checkName(this.emp.name);
			return utils.checkName(name);
		},
		chkMidlName() {
			const { midlName } = this.getEmployeesById(this.getEmpEditId);
			if (midlName === undefined) return utils.checkNameEmpty(this.emp.midlName);
			return utils.checkNameEmpty(midlName);
		},
		chkLastName() {
			const { lastName } = this.getEmployeesById(this.getEmpEditId);
			if (lastName === undefined) return utils.checkName(this.emp.lastName);
			return utils.checkName(lastName);
		},
		chkSalaryRate() {
			const { salary } = this.getEmployeesById(this.getEmpEditId);
			if (salary === undefined) return utils.checkFloatAndZero(this.emp.salary);
			return utils.checkFloatAndZero(salary);
		},
		chkExtraSalary() {
			const { extraTimeSalary } = this.getEmployeesById(this.getEmpEditId);
			if (extraTimeSalary === undefined) return utils.checkFloatAndZero(this.emp.extraTimeSalary);
			return utils.checkFloatAndZero(extraTimeSalary);
		},
		chkSalaryPeriod() {
			const { perDayOrHours } = this.getEmployeesById(this.getEmpEditId);
			if (perDayOrHours === undefined) return utils.checkEmpty(this.emp.perDayOrHours);
			return utils.checkEmpty(perDayOrHours);
		},
		showDialog: {
			get() {
				if (this.getShowEmpEditDial) this.test();
				return this.getShowEmpEditDial;
			},
			set(v) {
				this.setShowEmpEditDial(v);
			}
		},
		empName: {
			get() {
				const id = this.getEmpEditId;
				if (id === 0) return this.emp.name;
				return this.emp.name;
			},
			set(v) {
				this.emp.name = v;
				console.log('v', this.emp, v, this.emp.name);
			}
		},
		empMiddleName: {
			get() {
				return this.emp.midlName;
			},
			set(v) {
				this.emp.midlName = v;
			}
		},
		empEmploymentDate: {
			get() {
				return this.emp.timeOfJobStartD;
			},
			set(v) {
				const id = this.getEmpEditId;
				const poleVal = new Date(v).getTime() / 1000;
				if (this.getEmpEditId === 0) this.emp.timeOfJobStartD = poleVal;
				else this.setEmployeData({ poleName: 'timeOfJobStartD', id: id, poleVal: poleVal });
			}
		},
		empLastName: {
			get() {
				return this.emp.lastName;
			},
			set(v) {
				const id = this.getEmpEditId;
				if (this.getEmpEditId === 0) this.emp.lastName = v;
				else this.setEmployeData({ poleName: 'lastName', id: id, poleVal: v });
			}
		},
		empSalaryRate: {
			get() {
				return this.emp.salary;
			},
			set(v) {
				this.emp.salary = v;
			}
		},
		empExtraSalary: {
			get() {
				return this.emp.extraTimeSalary;
			},
			set(v) {
				this.emp.extraTimeSalary = v;
			}
		},
		empSalaryPeriod: {
			get() {
				return this.getEmpSalaryCycleId(this.emp.perDayOrHours).value;
			},
			set(v) {
				this.emp.perDayOrHours = v;
			}
		}
	},
	methods: {
		...mapMutations(
			'nimdaEmp',
			[
				'handleDialogCalcSalary',
				'setEmployeDeducted',
				'setShowEmpEditDial',
				'setEmployeData'
			]
		),
		...mapActions(
			'nimdaEmp',
			[
				'makeEmployeesSalary',
				'calcWorkTimeA',
				'CalcDaySalaryA',
				'saveEmployeSalaryDays',
				'SaveAndCalcSavary',
				'saveEmploye'
			]
		),
		test() {
			const id = this.getEmpEditId;
			const employee = this.getEmployeesById(id);
			Object.assign(this.emp, employee);
		}// ,
		// disabledDate(time) {
		// 	const add = 6 * 3600 * 24 * 1000;
		// 	const chk = time.getTime() < Date.now() - add || time.getTime() > Date.now() + add;
		// 	return chk;
		// },
	},
	mounted() {
		this.test();
	}
};
</script>>
<style scoped>
.border {
	border-top: 0px;
	border-right: 1px;
	border-left: 0px;
	border-bottom: 1px;
	border-style: solid;
	width: 305px !important;
	height: 109px;
	padding: 0px 0px 0px 0px;
	margin: 0;
}
.border-last {
	border-right: 0px;
	border-bottom: 0px;
}
.border-bot {
	border-bottom: 0px;
}
.scrollable {
	height: 220px;
	width: 919px;
	border-style: solid;
	border-width: 2px;
	display: flex;
	flex-wrap: wrap;
}
.info-div {
	height: 350px;
	width: 200px;
	border-style: solid;
	border-width: 1px;
	padding: 5px;
}
</style>

<template>
	<DialogPV
		v-model:visible="showCashAdvanceDialog"
		width="500px"
		header="Employe cashadvance">
		<div>
			<emp-select
				widthclass="w430"
			/>
		</div>
		<div>
			<InputText
				v-model="input"
				placeholder="Input cashadvance"
				class="w430 mt-3"
			/>
			<validate-sing
				:validateVal="input <= 0"
				:marginTop="4"
				:size="25"
			/>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					label="Cancel"
					severity="danger"
					@click="setshowCashAdvanceDialog({ b: false })" />
				<ButtonPV
					type="primary"
					:disabled="validateData"
					label="Save"
					@click="addCashAdvanceEmp({ value: this.getEmployeFromSelect, sum: this.input });" />
			</span>
		</template>
	</DialogPV>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
import InputText from 'primevue/inputtext';

// eslint-disable-next-line import/no-unresolved
import EmpSelect from '@/components/other/EmpSelect.vue';
// eslint-disable-next-line import/no-unresolved
import ValidateSing from '@/components/other/ValidateSing.vue';

export default {
	components: {
		EmpSelect,
		ButtonPV,
		DialogPV,
		InputText,
		ValidateSing
	},
	data() {
		return {
			input: 0
		};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getshowCashAdvanceDialog',
				'getEmployees',
				'getEmployeFromSelect'
			]
		),
		showCashAdvanceDialog: {
			get() {
				return this.getshowCashAdvanceDialog;
			},
			set(v) {
				this.setshowCashAdvanceDialog({ b: v });
			}
		},
		validateData() {
			if (this.getEmployeFromSelect === '' || this.input <= 0) return true;
			return false;
		}
	},
	methods: {
		...mapMutations(
			'nimdaEmp',
			[
				'setshowCashAdvanceDialog'
			]
		),
		...mapActions(
			'nimdaEmp',
			[
				'addCashAdvanceEmp'
			]
		)
	},
	mounted() {
		// this.makeEmployeesSalary();
	}
};
</script>>
<style scoped>
.scrollable {
	overflow: hidden;
	overflow-y: scroll;
	height: 350px;
	width: 720px;
	border-style: solid;
	border-width: 1px;
}
.info-div {
	height: 350px;
	width: 200px;
	border-style: solid;
	border-width: 1px;
	padding: 5px;
}
</style>

<template>
	<DialogPV
		v-model:visible="showDialog"
		style="width: 1400px;"
		header="Employe Job Calculator">
		<p>Add business hours to your calendar:</p>
		<table>
			<tr>
				<td valign="top">
					<div class="titles row-pv mss-1">
						<div class="cell242">
							<p class="mt-1 tc">Date and time of work</p>
						</div>
						<div class="cell95">
							<p class="mt-1 tc">Day salary</p>
						</div>
						<div class="cell75">
							<p class="mt-1 tc">Day off</p>
						</div>
						<div class="cell100">
							<p class="mt-1 tc">1/2 day</p>
						</div>
						<div class="cell110">
							<p class="mt-1 tc">Double pay</p>
						</div>
						<div class="cell160">
							<p class="mt-1 ms-2 tc">Set lunch time</p>
						</div>
						<div class="cell50">
							<p class="mt-1 tc">!</p>
						</div>
						<div class="cell85">
							<p class="mt-1 tc">Extra time</p>
						</div>
						<div class="cell90">
							<p class="mt-1 tc">Lunch time</p>
						</div>
					</div>
					<div style="height: 9px;" />
					<div class="scrollable">
						<div
							v-for="item in getEmployeesSalary"
							:key="item.id"
							style="width: 100%; float: left;"
						>
							<div class="cell100">
								<p
									class="tc mt-2"
								>{{ item.dayOfWeek }}, {{ item.date }} {{ item.mouth }}</p>
							</div>
							<div class="cell85">
								<DatePicker
									v-model="item.dates[0]"
									timeOnly
									showTime
									style="width: 70px;"
									fluid />
							</div>
							<div class="cell75">
								<DatePicker
									v-model="item.dates[1]"
									timeOnly
									showTime
									style="width: 70px;"
									fluid />
							</div>
							<div class="cell75">
								<InputText
									v-model="item.daySalary"
									disabled
									class="tc mt-1"
									style="width: 70px"
								/>
							</div>
							<div class="cell90">
								<Checkbox
									v-model="item.checkBoxses"
									class="tc mt-1 mt-075"
									:inputId="`DayOff${item.id}`"
									value="dayoff"
									@change="CalcDaySalaryA(item.id)" />
								<label
									:for="`DayOff${item.id}`"
									style="font-size: 6;"
									class="ml-2"> Day Off </label>
							</div>
							<div class="cell100">
								<Checkbox
									v-model="item.checkBoxses"
									class="tc mt-1 mt-075"
									:inputId="`halfday${item.id}`"
									value="halfday"
									@change="CalcDaySalaryA(item.id)" />
								<label
									:for="`halfday${item.id}`"
									style="font-size: 6;"
									class="ml-2"> Half day </label>
							</div>
							<div class="cell100">
								<Checkbox
									v-model="item.checkBoxses"
									class="tc mt-1 mt-075"
									:inputId="`doublepay${item.id}`"
									value="doublepay"
									@change="CalcDaySalaryA(item.id)" />
								<label
									:for="`doublepay${item.id}`"
									style="font-size: 6;"
									class="ml-2"> D. pay </label>
							</div>
							<div class="cell75">
								<DatePicker
									v-model="item.LBDates[0]"
									timeOnly
									showTime
									style="width: 80px;"
									fluid />
							</div>
							<div class="cell75">
								<DatePicker
									v-model="item.LBDates[1]"
									timeOnly
									showTime
									style="width: 80px;"
									fluid />
							</div>
							<div class="cell50">
								<i
									v-if="item.info[1] === 0"
									style="color: green"
									class="pi pi-check ms-3 mt-3" />
								<i
									v-if="item.info[1] > 0"
									style="color: red"
									class="pi pi-times ms-3 mt-3" />
							</div>
							<div class="cell90">
								<InputText
									v-model="item.extraTime"
									disabled
									class="tc mt-1"
									style="width: 84px"
								/>
							</div>
							<div class="cell90">
								<InputText
									v-model="item.LunchBreakTime"
									disabled
									class="tc mt-1"
									style="width: 84px"
								/>
							</div>
						</div>
					</div>
				</td>
				<td valign="top">
					<div class="ms-4 info-div scrollable">
						<p>Work time: {{ CalcTime }}</p>
						<p>Sum: {{ CalcSumSalary }}</p>
						<p>Extra work time: {{ CalcExtraTime }}</p>
						<p>Extra work sum: {{ CalcExtraDaySalary }}</p>
						<p><u>Sum to pay: {{ CalcTotalDaySalary }}</u></p>
						<p>Deducted: {{ getDeducted }}</p>
						<p><mark>Entitled to a sum: {{ getToWorker }}</mark></p>
						<p>Dates: {{ CalcDates }}</p>
						<p>Days: {{ CalcDays }}</p>
						<p>Hours: {{ CalcHours }}</p>
						<p>Minutes: {{ CalcMin }}</p>
						<div
							label-position="top"
						>
							<label
								style="font-size: 6;"
								for="cashAdvance"
								class="ml-2"> Cash advance </label>
							<InputText
								id="cashAdvance"
								v-model="cashAdvance"
								disabled />
							<label
								style="font-size: 6;"
								for="Deducted"
								class="ml-2"> Deducted </label>
							<InputText
								id="Deducted"
								v-model="deducted" />
							<label
								style="font-size: 6;"
								for="newCashAdvance"
								class="ml-2"> New amount cash advance </label>
							<InputText
								id="newCashAdvance"
								v-model="newCashAdvance"
								disabled />
						</div>
					</div>
				</td>
			</tr>
		</table>
		<template #footer>
			<span class="dialog-footer">
				<ButtonPV
					class="me-2"
					@click="SaveAndCalcSavary();">
					Pay Salary
				</ButtonPV>
				<ButtonPV
					class="me-2"
					severity="danger"
					label="Cancel"
					@click="showEmpCalcSalary({ dial: flase, id: 0 });" />
				<ButtonPV
					class="me-2"
					label="Save"
					@click="saveEmployeSalaryDays({ calc: false, sum: 0 });" />
			</span>
		</template>
	</DialogPV>
</template>

<script>

import ButtonPV from 'primevue/button';
import DialogPV from 'primevue/dialog';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';

import { mapActions, mapGetters, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils';

export default {
	components: {
		DialogPV,
		Checkbox,
		DatePicker,
		InputText,
		ButtonPV
	},
	data() {
		return {
			valueName: false,
			time: new Date('12/12/2024 15:00'),
			chBox: null
		};
	},
	computed: {
		...mapGetters(
			'nimdaEmp',
			[
				'getEmployeesSalary',
				'getIsSalaryCalcDialOpen',
				'CalcTime',
				'CalcSumSalary',
				'CalcTotalDaySalary',
				'CalcExtraDaySalary',
				'CalcDates',
				'CalcDays',
				'CalcHours',
				'CalcExtraTime',
				'CalcMin',
				'getEmployeCashAdvance',
				'getEmployeNewCashAdvance',
				'getEmployeDeducted'
			]
		),
		getLunchBreak() {
			return this.getEmployeesSalary.LBDates;
		},
		getDeducted() {
			return utils.number_format(utils.DoNumber(this.deducted), 2, '.', ' ');
		},
		getToWorker() {
			return utils.number_format(utils.DoNumber(this.CalcTotalDaySalary) - utils.DoNumber(this.deducted), 2, '.', ' ');
		},
		showDialog: {
			get() {
				return this.getIsSalaryCalcDialOpen;
			},
			set(v) {
				this.setIsSalaryCalcDialOpen(v);
			}
		},
		deducted: {
			get() {
				return this.getEmployeDeducted;
			},
			set(v) {
				this.setEmployeDeducted(v);
			}
		},
		cashAdvance: {
			get() {
				return this.getEmployeCashAdvance;
			},
			set(v) {
				console.log(v);
			}
		},
		newCashAdvance: {
			get() {
				return this.getEmployeNewCashAdvance;
			},
			set(v) {
				console.log(v);
			}
		}
	},
	methods: {
		...mapMutations(
			'nimdaEmp',
			[
				'setEmployeDeducted',
				'setIsSalaryCalcDialOpen'
			]
		),
		...mapActions(
			'nimdaEmp',
			[
				'CalcDaySalaryA',
				'saveEmployeSalaryDays',
				'SaveAndCalcSavary',
				'showEmpCalcSalary'
			]
		)
	}
};
</script>>
<style scoped>
.mt-075 {
	margin-top: 0.75rem !important;
}
.cell {
	float: left;
	width: 100px;
}
.cell75 {
	float: left;
	width: 75px;
}
.cell85 {
	float: left;
	width: 85px;
}
.cell242 {
	float: left;
	width: 242px;
}
.cell160 {
	float: left;
	width: 160px;
}
.cell50 {
	float: left;
	width: 50px;
}
.cell110 {
	float: left;
	width: 110px;
}
.cell100 {
	float: left;
	width: 100px;
}
.cell90 {
	float: left;
	width: 90px;
}
.cell95 {
	float: left;
	width: 90px;
}
.scrollable {
	overflow: hidden;
	overflow-y: scroll;
	height: 350px;
	width: 1030px;
	border-style: solid;
	border-width: 1px;
}
.titles {
	height: 30px;
	width: 1030px;
	border-style: solid;
	border-width: 1px 1px 0 1px;
}
.info-div {
	height: 340px;
	width: 200px;
	padding: 5px;
}
.mss-1 {
	margin-left: 0px !important;
}
.row-pv {
    display: flex; /* Используем Flexbox для размещения колонок в строку */
    margin: -10px; /* Убираем отступы между колонками */
}
</style>

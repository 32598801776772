<template>
	<div />
</template>

<script>
import {
	ElMessageBox,
	ElMessage
} from 'element-plus';
import { mapActions } from 'vuex';

export default {
	methods: {
		...mapActions('base', ['exitFromApp'])
	},
	mounted() {
		ElMessageBox.confirm(
			'Would you like to finish working with the application?. Continue?',
			'Warning',
			{
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}
		).then(() => {
			if (this.exitFromApp()) {
				this.$router.push({ name: 'login' });
				ElMessage({
					type: 'success',
					message: 'Exit success'
				});
			}
		}).catch(() => {
			ElMessage({ type: 'info', message: 'Exit canceled' });
			this.$router.push({ name: 'accaunt' });
		});
	}
};
</script>

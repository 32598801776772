import { ElMessage } from 'element-plus';
// import MessagePV from 'primevue/message';
// eslint-disable-next-line import/extensions, import/no-unresolved
import utils from '@/utils/utils.js';
// eslint-disable-next-line import/no-cycle, import/no-unresolved, import/extensions
import * as accauntApi from '@/api/accaunt.js';

export default {
	namespaced: true,
	state: {
		dates: [Number(Date.now()) - (30 * 24 * 60 * 60 * 1000), Date.now()],
		showDeleteColum: false,
		delBut: 'Show to delete',
		dialogFormVisible: false,
		saveResultAcc: true,
		mainFinansTable: [], // Данные таблицы прихода и расхода средств
		mainFinansTblHead: '', // Заголовки или колонки
		mainFinansTblLoaded: false, // загружена или нет
		saveMes: '',
		statisticArr: [],
		statisticReady: false,
		statisticItogoReady: false,
		statisticItogoArr: [],
		accMenuH: 0,
		days: 20,
		loadTask: '',
		openCloseDraver: false,
		staticDrawer: false,
		selectedStatic: null,
		balancesWalletsShow: false,
		balancesWalletsData: [],
		balancesWalletsLoad: false
	},
	getters: {
		getPersonalStat: (state) => {
			const sel = state.selectedStatic;
			if (sel === null || sel.length === 0 || sel === undefined) return false;
			return sel.includes('Personal');
		},
		getBusinessStat: (state) => {
			const sel = state.selectedStatic;
			if (sel === undefined || sel === null) return false;
			return sel.includes('Business');
		},
		getBalancesWalletsData: state => state.balancesWalletsData,
		getBalancesWallets: state => state.balancesWalletsShow,
		getDialogFormVisible: state => state.dialogFormVisible,
		getDelBut: state => state.delBut,
		getShowDeleteColum: state => state.showDeleteColum,
		getStaticDrawer: state => state.staticDrawer,
		getDates: state => state.dates,
		getSelectedStatic: state => state.selectedStatic,
		getOpenCloseDraver: state => state.openCloseDraver,
		getLoadTask: state => state.loadTask,
		getAccMenuH: state => state.accMenuH,
		getDays: state => state.days,
		getAccauntGridData: state => state.mainFinansTable,
		getStatisticItogoArr: state => state.statisticItogoArr,
		getStatisticArr: state => state.statisticArr,
		getAccauntGridHead: state => state.mainFinansTblHead,
		getAccauntTableLoaded: state => state.mainFinansTblLoaded,
		getsaveResult: state => state.saveResultAcc,
		getsaveMes: state => state.saveMes,
		getStatisticReady: state => state.statisticReady,
		getStatisticItogoReady: state => state.statisticItogoReady,
		getGridData: state => ({ fName, id }) => {
			const item = state.mainFinansTable.find(el => el.id === id);
			return item ? item[fName] : undefined;
		},
		getEdited: (state) => {
			if (state.mainFinansTable.length === 0) return false;
			if (!state.mainFinansTable.some(elem => elem.edited
				&& utils.DoNumber(elem.amount) > 0)) return false;
			// if (state.mainFinansTable.find(elem => (elem.edited ===
			// true && utils.DoNumber(elem.amount) > 0)) === undefined) return false;
			return true;
		}
	},
	mutations: {
		setSelectedStatic(state, val) {
			state.selectedStatic = val;
		},
		setBalancesWallets(state, val) {
			state.balancesWalletsShow = val;
		},
		setDialogFormVisible: (state, d) => {
			state.dialogFormVisible = d;
		},
		setDelBut: (state, d) => {
			state.delBut = d;
		},
		setShowDeleteColum: (state, d) => {
			state.showDeleteColum = d;
		},
		setStaticDrawer(state, d) {
			state.staticDrawer = d;
		},
		setDatesFirst(state, d) {
			state.dates[0] = d;
		},
		setDatesSecond(state, d) {
			state.dates[1] = d;
		},
		setChkBB(state, c) {
			state.chkBB = c;
		},
		setChkBP: (state, c) => {
			state.chkBP = c;
		},
		setDays(state, r) {
			state.days = r;
		},
		setLoadTask: (state, val) => {
			state.loadTask = val;
		},
		setAccMenuH(state, r) {
			state.accMenuH = r;
		},
		setStatisticItogoReady(state, r) {
			state.statisticItogoReady = r;
		},
		setStatisticReady(state, r) {
			state.statisticReady = r;
		},
		setStatisticArr(state, arr) {
			state.statisticArr = arr;
		},
		setStatisticItogoArr(state, arr) {
			state.statisticItogoArr = arr;
		},
		setAccauntGridData(state, table) {
			state.mainFinansTable = table;
		},
		setAccauntHeadGrid(state, head) {
			state.mainFinansTblHead = head;
		},
		setAccauntGridDataNewId(state, elem) {
			const fItem = state.mainFinansTable.find(item => item.id === elem.oldID);
			const walItem = state.mainFinansTable.find(
				item => item.id < elem.id && item.valuta === fItem.valuta
			);
			let walAm;
			if (walItem === undefined) walAm = 0;
			else walAm = utils.DoNumber(walItem.walletamount);
			if (fItem.creditspent === 'Spent') fItem.walletamount = Number(walAm) - utils.DoNumber(fItem.amount);
			else fItem.walletamount = Number(walAm) + utils.DoNumber(fItem.amount);
			fItem.walletamount = utils.number_format(fItem.walletamount, 2, '.', ' ');
			fItem.id = elem.id;
			fItem.edited = false;
		},
		setAccauntTableLoaded: function (state, s) {
			state.mainFinansTblLoaded = s;
		},
		setResultOfSave: function (state, { res, mes }) {
			state.saveResultAcc = res;
			state.saveMes = mes;
		},
		pushToAccGridData: (state, elem) => {
			state.mainFinansTable = elem.concat(state.mainFinansTable);
		},
		setGridData(state, { fName, val, id }) {
			const index = state.mainFinansTable.findIndex(item => item.id === id);
			if (index !== -1 && fName in state.mainFinansTable[index]) {
				state.mainFinansTable[index][fName] = val;
			}
		},
		deleteStaticticArr(state, index) {
			if (Number(index) === -1) return;
			state.statisticArr.splice(index, 1);
		},
		addItogoStaticticArr(state, row) {
			state.statisticArr.push(row);
		},
		deleteAccountGridDataM(state, { id }) {
			const secIndex = state.mainFinansTable.findIndex(el => el.id === id);
			state.mainFinansTable.splice(secIndex, 1);
		},
		setBalancesWalletsData(state, val) {
			state.balancesWalletsData = val;
			state.balancesWalletsLoad = true;
		},
		setOpenCloseDraver(state) {
			state.openCloseDraver = !state.openCloseDraver;
		},
		setNumberFormatForAccWallet(state, { index, amount }) {
			state.mainFinansTable[index].walletamount = utils.number_format(utils.DoNumber(state.mainFinansTable[index].walletamount) + Number(amount), 2, '.', ' ');
		}
	},
	actions: {
		updateGridData({ commit }, { fName, val, id }) {
			commit('setGridData', { fName, val, id });
		},
		getMunuPoint({
			state,
			getters,
			commit,
			dispatch
		}, { menuName }) {
			if (getters.getOpenCloseDraver) commit('setOpenCloseDraver');
			switch (menuName) {
			case 'dates':
				dispatch('loadAccauntGridData', { task: menuName, days: `${state.dates[0]}, ${state.dates[1]}` });
				break;
			case '20days':
				dispatch('loadAccauntGridData', { task: menuName, days: getters.getDays });
				break;
			case 'balanceswallets':
				commit('setBalancesWallets', true);
				break;
			case 'save':
				dispatch('saveAccauntGridData').then(() => {
					if (getters.getsaveResult === true) ElMessage({ type: 'success', message: 'All data saved.' });
					else ElMessage({ type: 'warning', message: `${getters.getsaveMes} The data is not saved.` });
				});
				break;
			case 'addConvertation':
				commit('setDialogFormVisible', !getters.getDialogFormVisible);
				break;
			case 'deleteBut':
				commit('setShowDeleteColum', !getters.getShowDeleteColum);
				if (!getters.getShowDeleteColum) commit('setDelBut', 'Show to delete');
				else commit('setDelBut', 'Hide to delete');
				break;
			case 'addwallet':
				commit('pushToAccGridData', [{
					id: `new ${Date.now()}`,
					amount: 0,
					creditspent: 'Wallet',
					goal: 'Wallet',
					derection: 'Wallet',
					dateofpayment: utils.getDateNow(),
					dateofrecord: utils.getDateNow(),
					valuta: 'PHP',
					walletamount: '',
					edited: true,
					support: 0,
					note: '-',
					action: 'Wallet'
				}]);
				break;
			case 'add':
				commit('pushToAccGridData', [{
					id: `new${Date.now()}`,
					amount: 0,
					creditspent: 'Spent',
					goal: 'BAR/Restaurant',
					derection: 'Business',
					dateofpayment: utils.getDateNow(),
					dateofrecord: utils.getDateNow(),
					valuta: 'PHP',
					walletamount: '',
					edited: true,
					support: 1,
					note: '-',
					action: 'BAR/Restaurant'
				}]);
				break;
			case 'statistic':
				commit('setStaticDrawer', !getters.getStaticDrawer);
				dispatch('statistic');
				break;
			default:
				break;
			}
		},
		// eslint-disable-next-line no-unused-vars
		statistic({ getters, commit, rootGetters }) {
			const arr = getters.getAccauntGridData;
			let uniqueNames = arr.filter((el, ind) => ind === arr.findIndex(elf => elf.goal === el.goal && elf.goal !== 'Wallet')).map(item => ({ goal: item.goal }));
			// выбрали на что тратили бабки за этот период
			let str = '';
			rootGetters['base/getValuta'].forEach((item) => { str += `"${item.name}Spent":"0.00","${item.name}Credit":"0.00",`; });
			const valutaArr = JSON.parse(`{${str.slice(0, -1)}}`);
			// собрали объект с для каждой валюты
			uniqueNames = uniqueNames.map(el => Object.assign(el, valutaArr));
			// добавили модный массив к уникальным названиям
			arr.forEach((el) => {
				let index; let
					teststr;
				if (el.creditspent !== 'Wallet'
					&& ((getters.getBusinessStat && el.derection === 'Business')
					|| (getters.getPersonalStat && el.derection === 'Personal'))) {
					index = uniqueNames.findIndex(item => item.goal === el.goal);
					teststr = el.valuta + el.creditspent;
					uniqueNames[index][teststr] = Number(uniqueNames[index][teststr])
						+ utils.DoNumber(el.amount);
				}
			});
			uniqueNames.forEach(el => Object.keys(el).forEach((item) => {
				if (item !== 'goal') el[item] = utils.number_format(el[item], 2, '.', ' ');
			}));
			let counnter = 1;
			uniqueNames.forEach((el) => { counnter += 1; el.id = counnter; });
			commit('setStatisticArr', uniqueNames);
			commit('setStatisticReady', true);
		},
		async saveAccauntGridData({
			getters, rootGetters, commit
		}) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const saveArr = getters.getAccauntGridData.filter(
				elem => elem.edited === true && utils.DoNumber(elem.amount) !== 0
			);
			if (saveArr.length === 0) return;
			const response = await accauntApi.saveGridData(saveArr);
			if (response.data.error === 0) {
				response.data.update.forEach((item) => {
					if (Number(item.error) === 0) commit('setAccauntGridDataNewId', item);
				});
				response.data.new.forEach((item) => {
					if (Number(item.error) === 0) commit('setAccauntGridDataNewId', item);
				});
				await commit('setResultOfSave', { res: true, mes: '' });
			}
		},
		async loadAccauntGridHead({ rootGetters, commit }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await accauntApi.loadGridColumn('accaunt', 'gettable');
			if (response.data.error === 0) {
				commit('setAccauntHeadGrid', response.data.data);
			}
		},
		async loadBalanceOnWallet({ rootGetters, commit }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			const response = await accauntApi.loadBalanceOnWallet();
			if (response.data.error === 0) {
				commit('setBalancesWalletsData', response.data.data);
			}
		},
		async loadAccauntGridData({ commit, rootGetters }, { task, days }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			if (task === 'days') commit('setDays', days);
			commit('setLoadTask', task);
			const response = await accauntApi.loadGridData(days, task);
			if (response.data.error === 0) {
				commit('setAccauntGridData', response.data.data);
				commit('setAccauntTableLoaded', true);
			}
		},
		async deleteAccauntGridData({ commit, rootGetters, dispatch }, { id }) {
			if (rootGetters['base/getGlobError'] !== 0) return;
			if (String(id).includes('new')) {
				commit('deleteAccountGridDataM', { id: id });
				ElMessage({ type: 'success', message: 'Row is deleted, successfully.' });
				return;
			}
			const response = await accauntApi.deleteGridData(id);
			if (response.data.error === 0) {
				if (response.data.data.id !== 0) dispatch('calcWalletAmount', { id: response.data.data.id });
				if (response.data.data.oldID !== 0) dispatch('calcWalletAmount', { id: response.data.data.oldID });
				await commit('setResultOfSave', { res: true, mes: '' });
				ElMessage({ type: 'success', message: 'Row is deleted, successfully.' });
			}
		},
		calcWalletAmount({ state, commit, dispatch }, { id }) {
			if (id === 0) return;
			const index = state.mainFinansTable.findIndex(el => el.id === id);
			if (index === -1) return;
			if (state.mainFinansTable[index].creditspent === 'Wallet') {
				dispatch('loadAccauntGridData', { task: state.loadTask, days: state.days });
				return;
			}
			const { valuta } = state.mainFinansTable[index];
			let amount = utils.DoNumber(state.mainFinansTable[index].amount);
			if (state.mainFinansTable[index].creditspent === 'Credit') {
				amount = 0 - amount;
			}
			for (let i = index; i > -1; i--) {
				if (state.mainFinansTable[i].valuta === valuta) {
					if (state.mainFinansTable[i].creditspent === 'Wallet') {
						commit('deleteAccountGridDataM', { id: id });
						return;
					}
					commit('setNumberFormatForAccWallet', { index: i, amount: amount });
				}
			}
			commit('deleteAccountGridDataM', { id: id });
		}
	}
};

<template>
	<Drawer
		v-model:visible="getOpenCloseDraverLoc"
		:with-header="false"
		size="330px"
	>
		<pi-button
			label="Info"
			style="width:100%"
			class="ms-1 me-2 mt-2 subbutton"
			rounded
			@click="getMunuPoint({ menuName: 'add' })">
			Add (opt+A)
		</pi-button>
		<pi-button
			type="primary"
			rounded
			class="ms-1 me-2 mt-2 subbutton"
			style="width:100%"
			:disabled="!getEdited"
			@click="getMunuPoint({ menuName: 'save' })">
			Save (opt+S)
		</pi-button>
		<pi-button
			type="primary"
			rounded
			style="width:100%"
			class="ms-1 me-2 mt-2 subbutton"
			@click="getMunuPoint({ menuName: 'addwallet' })"
		>
			Add wallet
		</pi-button>
		<pi-button
			class="subbutton"
			type="primary"
			style="width: 82%"
			rounded
			@click="getMunuPoint({ menuName: '20days' })">
			Show data for the last
		</pi-button>
		<InputText
			v-model="days"
			class="date-width mt-2 subbutton"
			placeholder="Start date"
		/>
		<DatePicker
			v-model="dateStart"
			dateFormat="dd-mm-yy"
			style="width: 44%"
		/>
		<DatePicker
			v-model="dateFinish"
			dateFormat="dd-mm-yy"
			style="width: 44%"
		/>
		<pi-button
			type="primary"
			style="width: 30px"
			class="mt-2 subbutton"
			@click="getMunuPoint({ menuName: 'dates' })">
			ok
		</pi-button>
		<pi-button
			type="primary"
			class="ms-1 me-2 mt-2 subbutton"
			rounded
			style="width:100%"
			@click="getMunuPoint({ menuName: 'deleteBut' })">
			{{ getDelBut }}
		</pi-button>
		<pi-button
			type="primary"
			rounded
			style="width:100%"
			class="ms-1 me-2 mt-2 subbutton"
			@click="getMunuPoint({ menuName: 'addConvertation' })">
			Add exchange
		</pi-button>
		<pi-button
			style="width:100%"
			rounded
			type="primary"
			class="ms-1 me-2 mt-2 subbutton"
			@click="getMunuPoint({ menuName: 'statistic' })">
			Statistics
		</pi-button>
		<pi-button
			style="width:100%"
			type="primary"
			rounded
			class="ms-1 me-2 mt-2 subbutton"
			@click="getMunuPoint({ menuName: 'balanceswallets' })">
			Balances on wallets
		</pi-button>
	</Drawer>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Drawer from 'primevue/drawer';
import PiButton from 'primevue/button';
import DatePicker from 'primevue/datepicker';
import InputText from 'primevue/inputtext';

export default {
	data() {
		return {
			date: null
		};
	},
	components: {
		Drawer,
		PiButton,
		DatePicker,
		InputText
	},
	computed: {
		...mapGetters(
			'accaunt',
			[
				'getOpenCloseDraver',
				'getEdited',
				'getDays',
				'getDates',
				'getDelBut'
			]
		),
		getOpenCloseDraverLoc: {
			get() {
				return this.getOpenCloseDraver;
			},
			set() {
				if (this.getOpenCloseDraver) this.setOpenCloseDraver();
			}
		},
		days: {
			get() {
				return this.getDays;
			},
			set(value) {
				this.setDays(value);
			}
		},
		dateStart: {
			get() {
				return new Date(this.getDates[0]);
			},
			set(value) {
				console.log(value);
				this.setDatesFirst(value);
			}
		},
		dateFinish: {
			get() {
				return new Date(this.getDates[1]);
			},
			set(value) {
				this.setDatesSecond(value);
			}
		}
	},
	methods: {
		...mapActions('accaunt', ['getMunuPoint']),
		...mapMutations(
			'accaunt',
			[
				'setOpenCloseDraver',
				'setDays',
				'setDatesFirst',
				'setDatesSecond',
				'statistic'
			]
		)
	}
};
</script>
<style scoped>
.date-width {
	width: 50px !important
}

.subbutton {
	background-color: var(--el-color-primary) !important;
	color: white !important;
}
</style>

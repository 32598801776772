import http from './http';
import { BASEURL } from '../store/constants';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

export async function loadCustomersData() {
	const response = http.post(
		BASEURL,
		{
			task: 'loadCustomersData',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}
export async function loadEmployeEMPTY() {
	const response = http.post(
		BASEURL,
		{
			task: 'loadEmploye',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

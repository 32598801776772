<template>
	<Drawer
		v-model:visible="drawer"
		title="Statistics of Employe."
		position="bottom"
		style="height: 75%;"
	>
		<div id="block1">
			<div class="elem-block">
				<div class="block">
					<span class="label-block">Employees</span>
					<emp-select
						:margintop="2"
						widthclass="w220"
					/>
				</div>
			</div>
			<div class="elem-block">
				<div class="block">
					<span class="label-block">Start Date</span>
					<DatePicker
						v-model="startDate"
						class="w220"
						dateFormat="dd/mm/yy"
					/>
					<validate-sing
						:validateVal="!chekStartDate"
						:marginTop="2" />
				</div>
			</div>
			<div class="elem-block">
				<div class="block">
					<span class="label-block">Finish Date</span>
					<DatePicker
						v-model="finishDate"
						dateFormat="dd/mm/yy"
						class="w220"
					/>
					<validate-sing
						:validateVal="!chekFinishDate"
						:marginTop="2" />
				</div>
			</div>
			<div class="elem-block">
				<div class="block">
					<ButtonPV
						type="primary"
						class="mt-4 w220"
						:disabled="!validateForm"
						@click="loadStatisticEmploye()">
						Salary for the period
					</ButtonPV>
				</div>
			</div>
			<div class="elem-block">
				<div class="block">
					<ButtonPV
						type="primary"
						class="mt-4 w220"
						severity="danger"
						label="Cancel"
						@click="drawer = false" />
				</div>
			</div>
		</div>
		<div
			v-if="showTable"
			id="block2"
			style="getWidth">
			<DataTable
				:value="getStatisticsEmpSalary"
				:tableStyle="`width: ${getTableWidth}; height: ${Math.round(getMainScreenSizeY / 4 * 3) - 50}`"
			>
				<Column
					v-for="col of getStatisticsEmpSalaryCol"
					:key="col.id"
					:columnWidths="col.maxWidth"
					:field="col.field"
					:style="`width: ${col.maxWidth}px`"
					:header="col.header" />
				<template #footer
				><div
					class="flex item-center footer"
				>
					Total salary sum is {{ calculateTotalSalaryAmount }}
				</div>
				</template>
			</DataTable>
		</div>
	</Drawer>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ButtonPV from 'primevue/button';
import Drawer from 'primevue/drawer';
import DatePicker from 'primevue/datepicker';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup';   // optional
// import Row from 'primevue/row';                   // optional

// eslint-disable-next-line import/no-unresolved
import EmpSelect from '@/components/other/EmpSelect.vue';
// eslint-disable-next-line import/no-unresolved
import ValidateSing from '@/components/other/ValidateSing.vue';

export default {
	components: {
		EmpSelect,
		Column,
		DataTable,
		Drawer,
		DatePicker,
		ButtonPV,
		ValidateSing
	},
	data() {
		return {
			value: ''
		};
	},
	props: {
		// drawer: { type: Boolean, default: false }
	},
	computed: {
		...mapGetters('base', ['getMainScreenSizeY', 'getMainScreenSizeX']),
		...mapGetters(
			'nimdaEmp',
			[
				'getStatisticsDrawer',
				'getStatisticsEmpSalary',
				'getEmployees',
				'getStatisticsEmpSalaryCol',
				'getStatisticsStartDate',
				'getStatisticsFinishDate',
				'calculateTotalSalaryAmount',
				'getEmployeFromSelect'
			]
		),
		getWidth() {
			return `${String(Math.round(this.getMainScreenSizeX) - 260)}px`;
		},
		getTableWidth() {
			return Math.round(this.getMainScreenSizeX) - 310;
		},
		drawer: {
			get() {
				return this.getStatisticsDrawer;
			},
			set(v) {
				this.startStatistics(v);
			}
		},
		startDate: {
			get() {
				if (this.getStatisticsStartDate === '') {
					const d = new Date();
					const t = d.getTime() - (24 * 3600 * 1000 * 31);
					this.setStatisticsDate({ value: Math.round(t / 1000), position: 'start' });
					return new Date(t);
				}
				return new Date(this.getStatisticsStartDate * 1000);
			},
			set(v) {
				const d = new Date(v).getTime() / 1000;
				this.setStatisticsDate({ value: Math.round(d), position: 'start' });
			}
		},
		finishDate: {
			get() {
				if (this.getStatisticsFinishDate === '') {
					const d = new Date().getTime() / 1000;
					this.setStatisticsDate({ value: Math.round(d), position: 'finish' });
					return new Date();
				}
				return new Date(this.getStatisticsFinishDate * 1000);
			},
			set(v) {
				const d = new Date(v).getTime() / 1000;
				this.setStatisticsDate({ value: d, position: 'finish' });
			}
		},
		showTable() {
			return this.getStatisticsEmpSalary.length > 0;
		},
		chekStartDate() {
			const dateS = new Date(this.startDate);
			if (Number.isNaN(dateS.getTime())) return false;
			const dateF = new Date(this.finishDate);
			if (Number.isNaN(dateF.getTime())) return true;
			if (dateF > dateS) return true;
			return false;
		},
		chekFinishDate() {
			const dateF = new Date(this.finishDate);
			if (Number.isNaN(dateF.getTime())) return false;
			const dateS = new Date(this.startDate);
			if (Number.isNaN(dateS.getTime())) return true;
			if (dateF > dateS) return true;
			return false;
		},
		validateForm() {
			if (this.chekStartDate && this.chekFinishDate && this.getEmployeFromSelect !== '') return true;
			return false;
		}
	},
	methods: {
		...mapActions(
			'nimdaEmp',
			[
				'startStatistics',
				'setStatisticsDate',
				'loadStatisticEmploye'
			]
		)
	}
};
</script>
<style scoped>
.item-center {
	align-items: center;
}
.flex {
	display: flex;
}
#block1 {
	float: left;
	display: block;
	width: 260px;
	margin: 2px;
}

#block2 {
	float: left;
	display: block;
	margin: 2px;
}
.footer {
	justify-content: center;
	height: 100%;
	background-color: var(--el-color-primary-light-7);
}
</style>

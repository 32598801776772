export default {
	namespaced: true,
	state: {
		mainMenu: 'main',
		subMenu: '',
		menuData:
		[
			{
				Name: 'Employees',
				Call: 'employees',
				options: [
					{ Name: 'Add employe', Call: 'addemployees_', disabled: false },
					{ Name: 'Add cashadvance', Call: 'addcashadvance_', disabled: false },
					{ Name: 'Statistics', Call: 'statistics_', disabled: false },
					{ Name: 'Dismiss', Call: 'dismissfromwork_', disabled: false },
					{ Name: 'Add card', Call: 'addcard_', disabled: false },
					{ Name: 'Information', Call: 'information_', disabled: false },
					{ Name: 'Work time', Call: 'work_time_', disabled: false },
					{ Name: 'Back', Call: 'back' }
				]
			}, {
				Name: 'User',
				Call: 'user',
				options: [
					{ Name: 'Add', Call: 'useradd_', disabled: false },
					{ Name: 'Save', Call: 'usersave_', disabled: true },
					{ Name: 'Back', Call: 'back', disabled: false }
				]
			}, {
				Name: 'Connect_CB',
				Call: 'uploadfile',
				options: [
					{ Name: 'Upload', Call: 'upload_', disabled: false }, // !this.getEditedUT
					{ Name: 'Back', Call: 'back', disabled: false }
				]
			}
		]
	},
	getters: {
		getMenuData: state => state.menuData,
		getMainMenu: state => state.mainMenu,
		getSubMenu: state => state.subMenu
	},
	mutations: {
		setMainMenu: function (state, v) {
			state.mainMenu = v;
		},
		setSubMenu: function (state, val) {
			state.subMenu = val;
		},
		setMenuDataByIndex: function (state, {
			menuIndex, index, nameOfVal, val
		}) {
			state.menuData[menuIndex].options[index][nameOfVal] = val;
		}
	},
	actions: {
		selectMenu({ commit, dispatch }, str) {
			if (!String(str).includes('_')) {
				commit('setMainMenu', str);
			} else {
				commit('setSubMenu', str);
			}
			switch (str) {
			case 'saveemployees_':
				dispatch('nimdaEmp/saveEmploye', {}, { root: true });
				break;
			case 'statistics_':
				dispatch('nimdaEmp/startStatistics', { value: true }, { root: true });
				break;
			case 'addcashadvance_':
				commit('nimdaEmp/setshowCashAdvanceDialog', { b: true }, { root: true });
				break;
			case 'dismissfromwork_':
				commit('nimdaEmp/setDismissEmp', { b: true }, { root: true });
				break;
			case 'addcard_':
				commit('nimdaEmp/setShowAddCard', true, { root: true });
				break;
			case 'addemployees_':
				commit('nimdaEmp/setShowEmpEditDial', { edit: true, id: 0 }, { root: true });
				break;
			case 'information_':
				commit('nimdaEmp/setEmpInfWinShow', true, { root: true });
				break;
			case 'work_time_':
				commit('nimdaEmp/setOpenTimeWorcDial', true, { root: true });
				break;
			case 'back':
				commit('setMainMenu', 'main');
				break;
			case 'usersave_':
				dispatch('nimda/saveUserTable', {}, { root: true });
				break;
			case 'useradd_':
				commit('nimda/addUser', {}, { root: true });
				break;
			default:
			}
		}
	}
};

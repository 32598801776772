<template>
	<transition
		name="fadeNG"
		mode="out-in"
	>
		<i
			v-if="validateVal"
			class="pi pi-exclamation-circle ms-2"
			style="font-size: 1.2rem; color: red" />
		<i
			v-else
			class="pi pi-check-circle ms-2"
			style="font-size: 1.2rem; color: green" />
	</transition>
</template>

<script>

export default {
	props: {
		validateVal: { type: Boolean, default: false },
		marginTop: { type: Number, default: 2 },
		size: { type: Number, default: 25 }
	},
	computed: {
		getMarginTop() {
			return `mt-${this.marginTop}`;
		}
	}
};
</script>

<style scoped>
.fadeNG-enter-active {
	animation: fadeIn 0.5s;
}

.fadeNG-leave-active {
	animation: fadeOut 0.5s;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: rotateY(-90deg);
	}

	to {
		opacity: 1;
		transform: rotateY(0);
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
		transform: rotateY(0);
	}

	to {
		opacity: 0;
		transform: rotateY(90deg);
	}
}
</style>

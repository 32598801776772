import http from './http';
import { BASEURL } from '../store/constants';
// eslint-disable-next-line import/no-cycle
import store from '../store/index';

export async function loadGridColumn(table, task) {
	const response = http.post(
		BASEURL,
		{
			table: table,
			task: task,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function deleteGridData(id) {
	const response = http.post(
		BASEURL,
		{
			id: id,
			task: 'delete',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function loadGridData(days, task) {
	const response = http.post(
		BASEURL,
		{
			days: days,
			task: task,
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function saveGridData(table) {
	const response = http.post(
		BASEURL,
		{
			table: table,
			task: 'saveAcc',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

export async function loadBalanceOnWallet() {
	const response = http.post(
		BASEURL,
		{
			task: 'balanceOnWallet',
			token: store.getters['base/getToken']
		},
		{
			headers: { 'Content-Type': 'application/json' },
			error: 'error'
		}
	);
	return response;
}

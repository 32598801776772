<template>
	<div class="card">
		<Menubar
			:model="getMenu"
			class="set-font"
		>
			<template #item="{ item, props }">
				<router-link
					v-slot="{ href, navigate }"
					:to="item.route"
					custom>
					<a
						:href="href"
						v-bind="props.action"
						@click="navigate">
						<span class="ml-2">{{ item.label }}</span>
					</a>
				</router-link>
			</template>
		</Menubar>
	</div>
	<div
		ref="divHeight"
		class="h100"
	>
		<router-view />
	</div>
</template>

<script>
//  creditspent
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import Menubar from 'primevue/menubar';

export default {
	data() {
		return {};
	},
	components: {
		Menubar
	},
	computed: {
		...mapGetters('base', ['getReg', 'getMenu', 'getToken', 'getGlobError']),
		activeIndex() {
			return this.$route.name;
		}
	},
	methods: {
		...mapActions('base', ['setmainScreenSize', 'loadMenu', 'chkToken']),
		...mapActions('nimdaEmp', ['loadEmploye', 'loadEmployeSalaryByDay', 'loadColunmEmpSalary']),
		resizeHandler() {
			const { divHeight } = this.$refs;
			this.setmainScreenSize({
				sizeY: divHeight.clientHeight,
				sizeX: divHeight.clientWidth
			});
		}
	},
	created() {
		window.addEventListener('resize', this.resizeHandler);
	},
	mounted: async function () {
		await this.chkToken();
		this.resizeHandler();
		if (this.getReg) {
			this.loadMenu();
			this.$router.push({ name: 'accaunt' });
		} else {
			this.$router.push({ name: 'login' });
		}
		this.loadColunmEmpSalary();
	},
	unmounted() {
		window.removeEventListener('resize', this.resizeHandler);
	}
};
</script>
<style>
.w430 {
	width: 430px;
}
.w400 {
	width: 400px;
}
.w220 {
	width: 220px;
}
.w250 {
	width: 250px;
}
</style>

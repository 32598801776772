<template>
	<div class="startDiv">
		<div>
			<nimda-side-menu-comp />
		</div>
		<div
			v-if="isMainMenuValid"
			class="gid-table startDiv"
			:style="{ width: `${Number(getMainScreenSizeX) - 181}px` }"
		>
			<connect-upload-file
				v-if="getMainMenu === 'uploadfile'"
			/>
			<user-table-st
				:show="getMainMenu === 'user'"
				:data="getUserData"
			/>
			<nimda-employe-comp
				v-if="getMainMenu === 'employees'"
			/>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

// import {
// 	ElMessageBox,
// 	ElMessage,
// 	ElSwitch
// } from 'element-plus';
// import { h } from 'vue';
// eslint-disable-next-line import/no-unresolved
import UserTableSt from '@/components/Nimda/UserTable.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaSideMenuComp from '@/components/other/SideMenuComp.vue';
// eslint-disable-next-line import/no-unresolved
import NimdaEmployeComp from '@/components/Nimda/NimdaEmp/NEComponent.vue';

import ConnectUploadFile from '../Сonnect/ConnectUploadFile.vue';

export default {
	components: {
		UserTableSt,
		ConnectUploadFile,
		NimdaSideMenuComp,
		NimdaEmployeComp
	},
	data() {
		return {
			formLabelWidth: '140px',
			name: '',
			message: '',
			tableData: []
		};
	},
	computed: {
		...mapGetters(
			'base',
			[
				'getMainScreenSizeY',
				'getMainScreenSizeX'
			]
		),
		...mapGetters('nimdaMain', ['getSubMenu', 'getMainMenu']),
		...mapGetters('nimdaUser', ['getUserData']),
		...mapGetters(
			'nimda',
			[
				'getDishSecItem',
				'getStorageHelp',
				'getOrderHelpText',
				'getOrderHelpAng',
				'getStorageHelpAng',
				'getdishesArr',
				'getDishSection',
				'getStorTableData',
				'getStorUnits',
				'getfItem',
				'getfSecItem',
				'getStorSection'
			]
		),
		isMainMenuValid() {
			return !this.getMainMenu.includes('_');
		},
		getStorTableDataSavedOnly() {
			return this.getStorTableData.filter(data => !String(data.id).includes('new'));
		}
	},
	methods: {
		...mapMutations('nimdaMain', ['setSubMenu']),
		...mapMutations(
			'nimda',
			[
				'chkDishSectionValue',
				'chkUnitValue',
				'chkSectionValue'
			]
		),
		...mapActions('nimdaUser', ['loadUserGridData']),
		...mapActions(
			'nimda',
			[
				'openIngradientWin',
				'loadStoreData',
				'addUnitsSections',
				'removeNotSaved',
				'saveUnitsSection',
				'editUnitSection',
				'loadDishes'
				// 'loadEmploye'
			]
		),
		...mapActions(
			'nimdaEmp',
			[
				'loadEmploye',
				'loadEmployeSalaryByDay',
				'loadColunmEmpSalary',
				'loadNimdaEmployeCol'
			]
		),
		...mapActions('nimdaUser', ['loadUserGridHead'])
		// pressAdd(a, b) { // вызывается после нажатия кнопки добавить
		// 	this.openIngradientWin({ mode: 'new', row: b }); // открывает окно инградиента
		// },
		// onCellClickNimda(e) { // Дивер для единиц
		// 	this.addUnitNimda(e.unitName, e.id);
		// // },
		// onCellClickDishSection(e, v) { // Дивер для dish sceion
		// 	let val = e.sectionName;
		// 	if (v.property === 'barResto') val = e.barResto;
		// 	this.addDishSectionNmd(val, e.id, v.property);
		// },
		// onCellClickSection(e) {
		// 	this.addSectionNimda(e.sectionName, e.id);
		// },
		// testUnits(a) {
		// 	this.chkUnitValue(a);
		// 	if (typeof this.getfItem === 'undefined') return /^[(:?A-Za-z\d)]+$/.test(a);
		// 	return false;
		// },
		// testSection(a) {
		// 	this.chkSectionValue(a);
		// 	if (typeof this.getfSecItem === 'undefined') return /^[(:?A-Za-z\d\s)]+$/.test(a);
		// 	return false;
		// },
		// testDishSection(a) {
		// 	this.chkDishSectionValue(a);
		// 	if (typeof this.getDishSecItem === 'undefined') return /^[(:?A-Za-z\d\s)]+$/.test(a);
		// 	return false;
		// },
		// confirmClick(a) {
		// 	this.saveUnitsSection(a);
		// 	this.setSubMenu('');
		// },
		// cancelClick() {
		// 	this.setSubMenu('');
		// 	this.removeNotSaved({ unit: true });
		// },
		// addUnitNimda(a, id) {
		// 	ElMessageBox.prompt('', '', {
		// 		confirmButtonText: 'OK',
		// 		cancelButtonText: 'Cancel',
		// 		inputValue: a,
		// 		inputValidator: this.testUnits,
		// 		inputErrorMessage: 'Invalid input',
		// 		closeOnPressEscape: true
		// 	})
		// 		.then((val) => {
		// 			ElMessage({ type: 'success', message: 'New item added to story, but not saved.' });
		// 			if (id === 'new') this.addUnitsSections({ type: 'unit', val: val.value.trim() });
		// 			else this.editUnitSection({ type: 'unit', val: val.value.trim(), id: id });
		// 		})
		// 		.catch(() => {
		// 			ElMessage({ type: 'warning', message: 'New item not added.' });
		// 		});
		// },
		// addDishSectionNmd(v, id, col) {
		// 	if (col === 'sectionName') {
		// 		ElMessageBox.prompt('', '', {
		// 			confirmButtonText: 'OK',
		// 			cancelButtonText: 'Cancel',
		// 			inputValue: v,
		// 			inputValidator: this.testDishSection,
		// 			inputErrorMessage: 'Invalid input!',
		// 			closeOnPressEscape: true
		// 		})
		// 			.then((val) => {
		// 				val.value = val.value[0].toUpperCase() + val.value.slice(1);
		// 				ElMessage({ type: 'success', message:
		// 'New item added to dish section, but not saved.' });
		// 				if (id === 'new')
		// this.addUnitsSections({ type: 'dishSection', val: val.value.trim() });
		// 				else this.editUnitSection({ type: 'dishSection', val: val.value.trim(), id: id });
		// 			})
		// 			.catch(() => {
		// 				ElMessage({ type: 'warning', message: 'New item not added.' });
		// 			});
		// 	} else {
		// 		let checked = false;
		// 		if (v === 'Bar') checked = true;
		// 		ElMessageBox({
		// 			confirmButtonText: 'OK',
		// 			cancelButtonText: 'Cancel',
		// 			showCancelButton: true,
		// 			title: '',
		// 			closeOnPressEscape: true,
		// 			message: () =>	h(ElSwitch, {
		// 				modelValue: checked.value,
		// 				activeText: 'Bar',
		// 				inactiveText: 'Restoran',
		// 				'onUpdate:modelValue': (val) => {
		// 					checked.value = val;
		// 				}
		// 			})
		// 		})
		// 			.then(() => {
		// 				ElMessage({ type: 'success', message: 'New item edited.' });
		// 				this.editUnitSection({ type: 'barResto', val: checked.value, id: id });
		// 			})
		// 			.catch(() => {
		// 				ElMessage({ type: 'warning', message: 'New item not edited.' });
		// 			});
		// 	}
		// },
		// addSectionNimda(a, id) {
		// 	ElMessageBox.prompt('', '', {
		// 		confirmButtonText: 'OK',
		// 		cancelButtonText: 'Cancel',
		// 		inputValue: a,
		// 		inputValidator: this.testSection,
		// 		inputErrorMessage: 'Invalid input.',
		// 		closeOnPressEscape: true
		// 	})
		// 		.then((val) => {
		// 			ElMessage({ type: 'success', message:
		// 'New "section" item added to story, but not saved.' });
		// 			if (id === 'new')	this.addUnitsSections({ type: 'section', val: val.value.trim() });
		// 			else this.editUnitSection({ type: 'section', val: val.value.trim(), id: id });
		// 		})
		// 		.catch(() => {
		// 			ElMessage({ type: 'warning', message: 'New item not added.' });
		// 		});
		// }
	},
	mounted() {
		this.loadColunmEmpSalary();
		this.loadUserGridData();
		this.loadEmployeSalaryByDay();
		this.loadUserGridHead();
		this.loadEmploye();
		this.loadNimdaEmployeCol();
	}
};
</script>
<style scoped>
.startDiv {
	display: flex;
}
</style>

/* eslint-disable import/no-unresolved */
import { createRouter, createWebHistory } from 'vue-router';

import AppLoginSt from '@/components/LoginPage.vue';
import AppNimda from '@/components/Nimda/NimdaPage.vue';
import AppAccaunt from '@/components/Accaunt/AccPage.vue';
import AppExit from '@/components/AppExit.vue';
import AppE404 from '@/components/E404St.vue';

const routes = [
	{
		name: 'login',
		path: '/login',
		component: AppLoginSt
	}, {
		name: 'accaunt',
		path: '/accaunt',
		component: AppAccaunt
	}, {
		name: 'adminka',
		path: '/adminka',
		component: AppNimda
	}, {
		name: 'exit',
		path: '/exit',
		component: AppExit
	}, {
		path: '/:any(.*)',
		component: AppE404
	}
];

export default createRouter({
	routes: routes,
	history: createWebHistory()
});

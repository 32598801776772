function getPublicPath() {
	switch (process.env.NODE_ENV) {
	case 'production': return ''; // '/api/';
	case 'development': return 'http://localhost:9999/api/';
	default: return '';
	}
}
function getBaseUploadUrl() {
	switch (process.env.NODE_ENV) {
	case 'production': return '';
	case 'development': return 'http://localhost:9999/upload/';
	default: return '';
	}
}
module.exports = {
	BASEURL: getPublicPath(),
	baseUploadUrl: getBaseUploadUrl()
};
